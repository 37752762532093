import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Reviews from "../views/Reviews.vue";
import store from "../store/index";
import NotFound from "../components/NotFound";
import ComingSoon from "../components/ComingSoon";
// import Login from '../views/Login.vue';
import vendorLogin from "../views/Pages/vendorLogin.vue";
import Register from "../views/Register.vue";
import vendorRegister from "../views/Pages/vendorRegister.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    props: true,
  },
  {
    path: "/Reviews",
    name: "Reviews",
    component: Reviews, 
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/SellersTerms",
    name: "SellersTerms",
    component: () => import("../views/SellersTerms.vue"),
  },
  {
    path: "/CustomerTerms",
    name: "CustomerTerms",
    component: () => import("../views/CustomerTerms.vue"),
  },
  {
    path: "/PrivacyPolicy",
    name: "PrivacyPolicy",
    component: () => import("../views/PrivacyPolicy.vue"),
  },
  {
    path: "/RefundPolicy",
    name: "RefundPolicy",
    component: () => import("../views/RefundPolicy.vue"),
  },
  {
    path: "/ComingSoon",
    name: "ComingSoon",
    component: ComingSoon,
    //props :true
  },
  {
    path: "/Alert",
    name: "Alert",
    component: () => import("../components/Alert.vue"),
  },
  {
    path: "/Auction",
    name: "Auction",
    component: () => import("../views/Auction.vue"),
  },
  // {
  //   path: "/Terms",
  //   name: "Terms",
  //   component: () => import("../views/Terms.vue"),
  // },
  {
    path: "/CarDetails/:url_key",
    name: "CarDetails",
    component: () => import("../views/Details.vue"),
    props: true,
  },
  {
    path: "/UpdateVechDetails",
    name: "UpdateVechDetails",
    component: () => import("../views/Pages/UpdateVechDetails.vue"),
    props: true,
  },
  {
    path: "/VechDetails/:url_key",
    name: "VechDetails",
    component: () => import("../views/Pages/VechDetails.vue"),
    props: true,
  },
  {
    path: "/RefundHistory",
    name: "RefundHistory",
    component: () => import("../views/RefundHistory.vue"),
  },

  {
    path: "/wonDetails/:url_key",
    name: "wonDetails",
    component: () => import("../views/wonVehDetails.vue"),
    props: true,
  },
  {
    path: "/AuctionList",
    name: "AuctionList",
    component: () => import("../views/AuctionList.vue"),
    props: true,
  },

  {
    path: "/CustomerPaymentHistory",
    name: "CustomerPaymentHistory",
    component: () => import("../views/CustomerPaymentHistory.vue"),
    props: true,
  },
  {
    path: "/PaymentDues",
    name: "PaymentDues",
    component: () => import("../views/customerPages/customerDues.vue"),
    props: true,
  },
  {
    path: "/ClaimHistory",
    name: "ClaimHistory",
    component: () => import("../views/customerPages/packageClaimHistory.vue"),
    //props: true
  },
  {
    path: "/bidhistory/:url_key/",
    name: "bidhistory",
    component: () => import("../views/customerPages/bidHistory.vue"),
    props: true,
  },
  {
    path: "/Vendor",
    name: "Vendor",
    component: () => import("../views/Pages/VendorProfile.vue"),
    props: true,
  },
  {
    path: "/AddVehicle",
    name: "AddVehicle",
    component: () => import("../views/Pages/AddVehicle.vue"),
    props: true,
  },
  {
    path: "/AuctionHistory",
    name: "AuctionHistory",
    component: () => import("../views/Pages/AuctionHistory.vue"),
    props: true,
  },
  {
    path: "/MyVehicleListings",
    name: "MyVehicleListings",
    component: () => import("../views/Pages/MyVehicleListings.vue"),
    props: true,
  },
  {
    path: "/LiveAuctions",
    name: "LiveAuctions",
    component: () => import("../views/Pages/LiveAuctions.vue"),
    props: true,
  },
  {
    path: "/WonCustomer",
    name: "WonCustomer",
    component: () => import("../views/Pages/WonCustomer.vue"),
    props: true,
  },
  {
    path: "/MyBidz",
    name: "mybids",
    component: () => import("../components/MyBidz.vue"),
    props: true,
  },
  {
    path: "/mywishList",
    name: "mywishList",
    component: () => import("../components/WishList.vue"),
  },
  {
    path: "/MyWinnings",
    name: "MyWinnings",
    component: () => import("../views/MyWinnings.vue"),
    props: true,
  },
  {
    path: "/package",
    name: "package",
    component: () => import("../views/package.vue"),
    props: true,
  },
  {
    path: "/packages",
    name: "packages",
    component: () => import("../views/seller/Packages.vue"),
    props: true,
  },
  {
    path: "/paymentSuccess",
    name: "paymentSuccess",
    component: () => import("../views/paymentSuccess.vue"),
    props: true,
  },
  // {
  //   path: '/ProcessWinningsCustomer',
  //   name: "ProcessWinningsCustomer",
  //   component: () => import("../views/ProcessWinningsCustomer.vue"),
  //   props: true
  // },
  {
    path: "/Changepassword",
    name: "Changepassword",
    component: () => import("../views/Changepassword.vue"),
    props: true,
  },
  {
    path: "/ProcessWinners",
    name: "ProcessWinners",
    component: () => import("../views/Pages/ProcessWinners.vue"),
    props: true,
  },
  {
    path: "/PendingPayments",
    name: "PendingPayments",
    component: () => import("../views/Pages/PendingPayments.vue"),
    props: true,
  },
  {
    path: "/PaymentHistory",
    name: "PaymentHistory",
    component: () => import("../views/Pages/PaymentHistory.vue"),
    props: true,
  },
  {
    path: "/Lost",
    name: "Lost",
    component: () => import("../views/vendor/lost.vue"),
    props: true,
  },
  {
    path: "/VendorChangePassword",
    name: "VendorChangePassword",
    component: () => import("../views/Pages/VendorChangePassword.vue"),
    props: true,
  },
  {
    path: "/processPayment",
    name: "processPayment",
    component: () => import("../views/payment/processPayment.vue"),
    props: true,
  },
  {
    path: "/payment/responseHandler",
    name: "responseHandler",
    component: () => import("../views/payment/responseHandler.vue"),
    props: true,
  },  
  {
    path: "/payment/verification",
    name: "verification",
    component: () => import("../views/payment/verification.vue"),
    props: true,
  },
  {
    path: "/payment/success",
    name: "success",
    component: () => import("../views/payment/success.vue"),
    props: true,
  },
  {
    path: "/payment/failure",
    name: "failure",
    component: () => import("../views/payment/failure.vue"),
    props: true,
  },
  // {
  //   path: "/Login",
  //   name: "Login",
  //   component: Login

  // },
  {
    path: "/vendorLogin",
    name: "vendorLogin",
    component: vendorLogin,
  },
  {
    path: "/Register",
    name: "Register",
    component: Register,
  },
  {
    path: "/vendorRegister",
    name: "vendorRegister",
    component: vendorRegister,
  },
  // {
  //   path: '/profile',
  //   name: 'profile',
  //   // lazy-loaded
  //   component: () => import('../views/Profile.vue')
  // },
  {
    path: "/forgotpassword",
    name: "forgotpassword",
    // lazy-loaded
    component: () => import("../views/Forgotpassword.vue"),
  },
  {
    path: "/vendorForgotpassword",
    name: "vendorforgotpassword",
    // lazy-loaded
    component: () => import("../views/Pages/vendorForgotpassword"),
  },
  // {
  //   path: '/changepassword',
  //   name: 'changepassword',
  //   // lazy-loaded
  //   component: () => import('../views/Changepassword.vue')
  // },
  {
    path: "/admin",
    name: "admin",
    // lazy-loaded
    component: () => import("../views/BoardAdmin.vue"),
  },
  {
    path: "/mod",
    name: "moderator",
    // lazy-loaded
    component: () => import("../views/BoardModerator.vue"),
  },
  {
    path: "/user",
    name: "user",
    // lazy-loaded
    component: () => import("../views/BoardUser.vue"),
  },
  {
    path: "/Checkout",
    name: "Checkout",
    component: () => import("../views/Checkout.vue"),
  },
  {
    path: "/MyAccount",
    name: "MyAccount",
    component: () => import("../views/MyAccount.vue"),
  },
  {
    path: "/testcard",
    name: "testcard",
    component: () => import("../views/testcard.vue"),
  },
  {
    path: "/testcrd",
    name: "testcrd",
    component: () => import("../views/testcrd.vue"),
    props: true,
  },
  {
    path: "/Contact",
    name: "Contact",
    component: () => import("../views/Contact.vue"),
  },
  {

    path: "/loading",
    name: "loading",
    component: () => import("../views/loading.vue"), 
  },
  {
    path: "/FeedBackForm",
    name: "FeedBackForm",
    component: () => import("../components/FeedBackForm.vue"),
  },

  {
    path: "/:catchAll(.*)",
    component: NotFound,
    name: "NotFound",
  },

  // {
  //   path: "/admin",
  //   name: "admin",
  //   component: () => import("../views/admin.vue"),
  // },
];

const router = new VueRouter({
  mode: "history",
  // base: process.env.BASE_URL,
  routes,
  // scrollBehavior() {
  //   document.getElementById('app').scrollIntoView({
  //     behavior: 'smooth'
  //   });
  // }
  //   scrollBehavior() {
  //     document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  // }
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
    next("/");
  } else {
    next();
  }
});

export default router;
