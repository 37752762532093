<template>
  <div>
    <div>
      <main class="page-content">
        <div class="account-page-area vendordashboard">
          <div class="container containerDash ">
            <div class="row">
              <div class="col-lg-3 account_border-right">
                <sidebar-dashboard></sidebar-dashboard>
              </div>
              <div class="col-lg-9" v-if="isLoading">
                <notfound :checkdata="isLoading"></notfound>
              </div>
              <div class="col-lg-9" v-else>
                <h3>Add vehicle</h3>
                <form
                  action="#"
                  class="uren-form"
                  @submit.prevent="addvechicle"
                >
                  <div class="uren-form-inner">
                    <!-- Brand Name* -->
                    <div class="row">
                      <div class="col-md-4">
                        <div>
                          <div class="single-input">
                            <label for="account-details-lastname1"
                              >Brand <span style="color: red">*</span>
                            </label>
                            <!-- <div
                              class="dropdown-wrapper"
                              style="position: relative"
                            >
                              <input
                                v-model="st.vehmake"
                                type="text"
                                @change="clearAllFields"
                                placeholder="Enter your brand name"
                                name="Brand"
                                v-validate="'required|max:100'"
                              />
                              <i
                                class="fa fa-angle-down"
                                aria-hidden="true"
                              ></i>
                              <div v-if="filterByBrand" class="vehSelection">
                                <div
                                  v-for="(car, index) in filterByBrand"
                                  :key="index"
                                >
                                  <span
                                    class="dropdown-item"
                                    v-if="
                                      st.vehmake != car.make && st.vehmake != ''
                                    "
                                    @click="getModelList(car.make)"
                                    >{{ car.make }}</span
                                  >
                                </div>
                              </div>
                            </div> -->
                            <select
                              class="form-control"
                              v-model="st.vehmake"
                              name="Brand"
                              v-validate="'required|max:100'"
                              @change="getModelList(st.vehmake)"
                            >
                              <option value="" selected disabled>
                                Select Vehicle Model
                              </option>
                              <option
                                v-for="(st, index) in VechBrand"
                                :key="index"
                                :value="st.make"
                              >
                                {{ st.make }}
                              </option>
                            </select>
                            <span
                              v-show="errors.has('Brand')"
                              class="help is-danger errorMes"
                              >{{ errors.first("Brand") }}</span
                            >
                            <!-- <label for="errorMes" class="errorMes">{{
                              VechBrandmessage
                            }}</label> -->
                          </div>
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div>
                          <div class="single-input">
                            <label for="account-details-lastname0"
                              >Model <span style="color: red">*</span>
                            </label>

                            <div
                              style="position: relative"
                              class="dropdown-wrapper"
                            >
                              <!-- <input
                                v-model="st.vehmodel"
                                type="text"
                                @change="clearVarientFields"
                                placeholder="Enter your model name"
                                v-validate="'required|max:100'"
                                name="Model"
                              />
                              <i
                                class="fa fa-angle-down"
                                aria-hidden="true"
                              ></i>
                              <div v-if="filterByModel" class="vehSelection">
                                <div
                                  v-for="(car, index) in filterByModel"
                                  :key="index"
                                >
                                  <span
                                    class="dropdown-item"
                                    v-if="
                                      st.vehmodel != car.model &&
                                      st.vehmodel != ''
                                    "
                                    @click="getVariantList(car.model)"
                                    >{{ car.model }}</span
                                  >
                                </div>
                              </div> -->
                            </div>

                            <!--  -->
                            <select
                              class="form-control"
                              v-model="st.vehmodel"
                              name="Model"
                              v-validate="'required|max:100'"
                              @change="getVariantList(st.vehmodel)"
                            >
                              <option value="" selected disabled>
                                Select Vehicle Model
                              </option>
                              <option
                                v-for="(st, index) in VechModel"
                                :key="index"
                                :value="st.model"
                              >
                                {{ st.model }}
                              </option>
                            </select>
                            <!-- <label for="errorMes" class="errorMes">{{
                              VechModelmessage
                            }}</label> -->
                            <span
                              v-show="errors.has('Model')"
                              class="help is-danger errorMes"
                              >{{ errors.first("Model") }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div>
                          <!-- Variant  -->
                          <div class="single-input">
                            <label for="account-details-lastname0"
                              >Variant <span style="color: red">*</span>
                            </label>

                            <select
                              class="form-control"
                              v-model="st.vehVariant"
                              name="Variant"
                              v-validate="'required|max:100'"
                            >
                              <option value="" selected disabled>
                                Select Vehicle Variant
                              </option>
                              <option
                                v-for="(st, index) in VechVariant"
                                :key="index"
                                :value="st.variant"
                              >
                                {{ st.variant }}
                              </option>
                            </select>
                            <!-- <label for="errorMes" class="errorMes">{{
                              VechVariantemessage
                            }}</label> -->
                            <span
                              v-show="errors.has('Variant')"
                              class="help is-danger errorMes"
                              >{{ errors.first("Variant") }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-4">
                        <div>
                          <!-- vehicle type  -->
                          <div class="single-input">
                            <label for="account-details-lastname0"
                              >Vehicle Types <span style="color: red">*</span>
                            </label>

                            <select
                              class="form-control"
                              v-model="st.vehTypeId"
                              name="Vehicle Type"
                              v-validate="'required|max:100'"
                            >
                              <option value="" selected disabled>
                                Select Vehicle Type
                              </option>
                              <option
                                v-for="st in VechType"
                                :value="st.vehTypeId"
                                :key="st.vehTypeId"
                                v-validate="'required|max:100'"
                              >
                                {{ st.vehTypeName }}
                              </option>
                            </select>
                            <!-- <label for="errorMes" class="errorMes">{{
                              VechVariantemessage
                            }}</label> -->
                            <span
                              v-show="errors.has('Vehicle Type')"
                              class="help is-danger errorMes"
                              >{{ errors.first("Vehicle Type") }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div>
                          <!-- Insurance Type -->
                          <div class="single-input">
                            <label for="account-details-lastname8">
                              Insurance Type
                            </label>
                            <select
                              class="form-control"
                              v-model="st.InsuranceTypeId"
                              name="Insurance Type"
                            >
                              <!-- v-validate="'required|max:100'" -->
                              <option value="" selected disabled>
                                Select Insurance Types
                              </option>
                              <option
                                v-for="st in InsuranceType"
                                :value="st.InsuranceTypeId"
                                :key="st.InsuranceTypeId"
                              >
                                {{ st.InsuranceTypeName }}
                              </option>
                            </select>
                            <!-- <label for="errorMes" class="errorMes">{{
                              Vechinsurancemessage
                            }}</label> -->
                            <span
                              v-show="errors.has('Insurance Type')"
                              class="help is-danger errorMes"
                              >{{ errors.first("Insurance Type") }}</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div>
                          <!-- Color -->
                          <div class="single-input">
                            <label for="account-details-lastname1"
                              >Color
                            </label>

                            <input
                              type="text"
                              name="Color"
                              id="account-details-lastnae2"
                              v-model="vehiclenfo.colorState"
                              placeholder="Color"
                            />
                            <!-- v-validate="'required|min:3|max:100'" -->
                            <span
                              v-show="errors.has('Color')"
                              class="help is-danger errorMes"
                              >{{ errors.first("Color") }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-4">
                        <div>
                          <div class="single-input">
                            <!-- <label for="account-details-lastname1">HP </label>

                            <input
                              type="number"
                              name="Hp"
                              id="account-details-lastnae2"
                              v-model="vehiclenfo.hpState"
                              placeholder="HP"
                            /> -->

                            <label for="account-details-lastname1"
                              >Transmission
                              <span style="color: red">*</span></label
                            >

                            <select
                              class="form-control"
                              name="Transmission"
                              v-model="vehiclenfo.hpState"
                              v-validate="'required'"
                            >
                              <option disabled>Select transmission type</option>
                              <option value="Manual">Manual</option>
                              <option value="Automatic">Automatic</option>
                            </select>

                            <!-- v-validate="'required|min:1|numeric'" -->
                            <span
                              v-show="errors.has('Transmission')"
                              class="help is-danger errorMes"
                              >{{ errors.first("Transmission") }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div>
                          <div class="single-input">
                            <label for="account-details-lastname1"
                              >Registration Number
                              <span style="color: red">*</span>
                            </label>

                            <input
                              type="text"
                              name="Registration Number"
                              v-validate="'required|min:3|max:100'"
                              placeholder="MP 09 AB 1234"
                              id="account-details-lastnae2"
                              v-model="vehiclenfo.vehRegNo"
                            />
                            <span
                              v-show="errors.has('Registration Number')"
                              class="help is-danger errorMes"
                              >{{ errors.first("Registration Number") }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div>
                          <div class="single-input">
                            <label for="account-details-firstname2"
                              >Km Clocked <span style="color: red">*</span>
                            </label>
                            <input
                              type="text"
                              name="KM Clocked"
                              v-validate="'required|max:20|numeric'"
                              id="account-details-firstname2"
                              v-model="vehiclenfo.kmClocked"
                              placeholder="KM Clocked"
                            />

                            <span
                              v-show="errors.has('KM Clocked')"
                              class="help is-danger errorMes"
                              >{{ errors.first("KM Clocked") }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-4">
                        <div>
                          <div class="single-input">
                            <label for="account-details-lastne3"
                              >Price <span style="color: red">*</span>
                            </label>
                            <input
                              type="text"
                              name="Price"
                              v-validate="'required|min:3|max:20|numeric'"
                              id="account-details-lasame3"
                              v-model="vehiclenfo.vehPrice"
                              placeholder="Price"
                            />

                            <span
                              v-show="errors.has('Price')"
                              class="help is-danger errorMes"
                              >{{ errors.first("Price") }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div>
                          <div class="single-input">
                            <label for="account-details-firstname4"
                              >Year of make
                              <span style="color: red">*</span></label
                            >
                            <input
                              type="text"
                              name="Year of make"
                              id="account-details-firstname4"
                              v-model="vehiclenfo.manYear"
                              placeholder="Year of make"
                              v-validate="'required|min:3|max:20|numeric'"
                            />
                            <!-- v-validate="'required|min:4|max:20|numeric'" -->
                            <span
                              v-show="errors.has('Year of make')"
                              class="help is-danger errorMes"
                              >{{ errors.first("Year of make") }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div>
                          <div class="single-input">
                            <label for="account-details-lastname8"
                              >State <span style="color: red">*</span>
                            </label>
                            <select
                              class="form-control"
                              @change="getDistrictbyLocId($event)"
                              v-model="st.state"
                              name="State"
                              v-validate="'required'"
                            >
                              <option value="" selected disabled>
                                Select State
                              </option>
                              <option
                                v-for="st in States"
                                :value="st.state"
                                :key="st.state"
                              >
                                {{ st.state }}
                              </option>
                            </select>
                            <!-- <label for="errorMes" class="errorMes">{{
                              statemessage
                            }}</label> -->
                            <span
                              v-show="errors.has('State')"
                              class="help is-danger errorMes"
                              >{{ errors.first("State") }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-4">
                        <div>
                          <div class="single-input">
                            <label for="account-details-lastname8"
                              >Districts <span style="color: red">*</span>
                            </label>
                            <select
                              class="form-control"
                              @change="getLocationByStateId($event)"
                              v-model="st.district"
                              name="District"
                              v-validate="'required'"
                            >
                              <option value="" selected disabled>
                                Select District
                              </option>
                              <option
                                v-for="st in Districts"
                                :value="st.district"
                                :key="st.district"
                              >
                                {{ st.district }}
                              </option>
                            </select>
                            <!-- <label for="errorMes" class="errorMes">{{
                              districtmessage
                            }}</label> -->
                            <span
                              v-show="errors.has('District')"
                              class="help is-danger errorMes"
                              >{{ errors.first("District") }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div>
                          <div class="single-input">
                            <label for="account-details-lastnam"
                              >City <span style="color: red">*</span>
                            </label>
                            <select
                              class="form-control"
                              v-model="vehiclenfo.ct"
                              name="City"
                              v-validate="'required'"
                            >
                              <option value="" selected disabled>
                                Select City
                              </option>
                              <option
                                v-for="ct in City"
                                :value="ct.locId"
                                :key="ct.locId"
                              >
                                {{ ct.locName }}
                              </option>
                            </select>
                            <!-- <label for="errorMes" class="errorMes">{{
                              citymessage
                            }}</label> -->
                            <span
                              v-show="errors.has('City')"
                              class="help is-danger errorMes"
                              >{{ errors.first("City") }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div>
                          <!-- Fuel Type -->
                          <div class="single-input">
                            <label for="account-details-lastname8">
                              Fuel Type <span style="color: red">*</span>
                            </label>
                            <select
                              class="form-control"
                              v-model="st.fuelType"
                              name="Fuel Type"
                              v-validate="'required'"
                            >
                              <!-- v-validate="'required|max:100'" -->
                              <option value="" selected disabled>
                                Select Fuel Types
                              </option>
                              <option
                                v-for="st in FuelType"
                                :value="st.fuelTypeId"
                                :key="st.fuelTypeId"
                              >
                                {{ st.fuelTypeName }}
                              </option>
                            </select>
                            <!-- <label for="errorMes" class="errorMes">{{
                              Vechinsurancemessage
                            }}</label> -->
                            <span
                              v-show="errors.has('Fuel Type')"
                              class="help is-danger errorMes"
                              >{{ errors.first("Fuel Type") }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div>
                          <!-- Ownership -->
                          <div class="single-input">
                            <label for="Ownership">
                              Ownership <span style="color: red">*</span>
                            </label>
                            <select
                              class="form-control"
                              v-model="vehiclenfo.ownership"
                              name="Ownership"
                              v-validate="'required'"
                            >
                              <!-- v-validate="'required|max:100'" -->
                              <option value="" selected disabled>
                                Select Ownership
                              </option>
                              <option value="Owner 1">Owner 1</option>
                              <option value="Owner 2">Owner 2</option>
                              <option value="Owner 3">Owner 3</option>
                              <option value="Owner 4">Owner 4</option>
                            </select>

                            <span
                              v-show="errors.has('Ownership')"
                              class="help is-danger errorMes"
                              >{{ errors.first("Ownership") }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-3 col-6">
                        <div>
                          <!-- ABS -->
                          <div class="single-input">
                            <label for="account-details-lastname8">ABS</label>
                            <label
                              style="display: inline-block; margin-right: 12px"
                              class="radio is-inline"
                              v-for="absState in radioButton"
                              :key="absState.id"
                            >
                              <input
                                style="margin-top: 0px; height: 25px"
                                type="radio"
                                :value="absState.id"
                                class="radio-input"
                                name="ABS"
                                v-model="vehiclenfo.absState"
                                placeholder="Year of make"
                              />
                              <span class="radio-label">{{
                                absState.name
                              }}</span>
                            </label>

                            <span
                              v-show="errors.has('ABS')"
                              class="help is-danger errorMes"
                              >{{ errors.first("ABS") }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 col-6">
                        <div class="">
                          <!-- Accidental -->
                          <div class="single-input">
                            <label for="account-details-lastname8"
                              >Accidental</label
                            >
                            <label
                              style="display: inline-block; margin-right: 12px"
                              class="radio is-inline"
                              v-for="accidentalState in radioButton"
                              :key="accidentalState.id"
                            >
                              <input
                                style="margin-top: 0px; height: 25px"
                                type="radio"
                                :value="accidentalState.id"
                                v-model="vehiclenfo.accidentalState"
                                class="radio-input"
                                name="Accidental"
                              />
                              <span class="radio-label">{{
                                accidentalState.name
                              }}</span>
                            </label>

                            <span
                              v-show="errors.has('Accidental')"
                              class="help is-danger errorMes"
                              >{{ errors.first("Accidental") }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 col-6">
                        <div>
                          <!-- Roof AC-->
                          <div class="single-input">
                            <label for="account-details-lastname8"
                              >Roof AC</label
                            >
                            <label
                              style="display: inline-block; margin-right: 12px"
                              class="radio is-inline"
                              v-for="adjustableSteeringState in radioButton"
                              :key="adjustableSteeringState.id"
                            >
                              <input
                                style="margin-top: 0px; height: 25px"
                                type="radio"
                                :value="adjustableSteeringState.id"
                                class="radio-input"
                                name="Roof AC"
                                v-model="vehiclenfo.adjustableSteeringState"
                              />
                              <span class="radio-label">{{
                                adjustableSteeringState.name
                              }}</span>
                            </label>

                            <span
                              v-show="errors.has('Roof AC')"
                              class="help is-danger errorMes"
                              >{{ errors.first("Roof AC") }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 col-6">
                        <div>
                          <!-- Alloy Wheels -->
                          <div class="single-input">
                            <label for="account-details-lastname8">
                              Alloy Wheels</label
                            >
                            <label
                              style="display: inline-block; margin-right: 12px"
                              class="radio is-inline"
                              v-for="alloyWheelsState in radioButton"
                              :key="alloyWheelsState.id"
                            >
                              <input
                                style="margin-top: 0px; height: 25px"
                                type="radio"
                                :value="alloyWheelsState.id"
                                class="radio-input"
                                name="Alloy Wheels"
                                v-model="vehiclenfo.alloyWheelsState"
                              />
                              <span class="radio-label">{{
                                alloyWheelsState.name
                              }}</span>
                            </label>

                            <span
                              v-show="errors.has('Alloy Wheels')"
                              class="help is-danger errorMes"
                              >{{ errors.first("Alloy Wheels") }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-3 col-6">
                        <div>
                          <!-- Anti Theft Device -->
                          <div class="single-input">
                            <label for="account-details-lastname8">
                              Anti Theft</label
                            >
                            <label
                              style="display: inline-block; margin-right: 12px"
                              class="radio is-inline"
                              v-for="antiTheftDeviceState in radioButton"
                              :key="antiTheftDeviceState.id"
                            >
                              <input
                                style="margin-top: 0px; height: 25px"
                                type="radio"
                                :value="antiTheftDeviceState.id"
                                class="radio-input"
                                name="Anti Theft"
                                v-model="vehiclenfo.antiTheftDeviceState"
                              />
                              <span class="radio-label">{{
                                antiTheftDeviceState.name
                              }}</span>
                            </label>

                            <span
                              v-show="errors.has('Anti Theft')"
                              class="help is-danger errorMes"
                              >{{ errors.first("Anti Theft") }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 col-6">
                        <div>
                          <!-- Power window Compatibility -->
                          <div class="single-input single-input-half">
                            <label for="account-details-lastname8">
                              Power Window</label
                            >
                            <label
                              style="display: inline-block; margin-right: 12px"
                              class="radio is-inline"
                              v-for="auxCompatibilityState in radioButton"
                              :key="auxCompatibilityState.id"
                            >
                              <input
                                style="margin-top: 0px; height: 25px"
                                type="radio"
                                class="radio-input"
                                name="Power window"
                                :value="auxCompatibilityState.id"
                                v-model="vehiclenfo.auxCompatibilityState"
                              />
                              <span class="radio-label">{{
                                auxCompatibilityState.name
                              }}</span>
                            </label>
                            <!-- <div class="errorMes">
                              {{ errors.first("powerWindowState") }}
                            </div> -->
                            <span
                              v-show="errors.has('Power window')"
                              class="help is-danger errorMes"
                              >{{ errors.first("Power window") }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 col-6">
                        <div>
                          <!-- Rear Wiper -->
                          <div class="single-input">
                            <label for="account-details-lastname8">
                              Rear Wiper</label
                            >
                            <label
                              style="display: inline-block; margin-right: 12px"
                              class="radio is-inline"
                              v-for="bluetoothState in radioButton"
                              :key="bluetoothState.id"
                            >
                              <input
                                style="margin-top: 0px; height: 25px"
                                type="radio"
                                :value="bluetoothState.id"
                                class="radio-input"
                                name="Rear Wiper"
                                v-model="vehiclenfo.bluetoothState"
                              />
                              <span class="radio-label">{{
                                bluetoothState.name
                              }}</span>
                            </label>
                            <!-- <div class="errorMes">
                              {{ errors.first("rearWiper") }}
                            </div> -->
                            <span
                              v-show="errors.has('Rear Wiper')"
                              class="help is-danger errorMes"
                              >{{ errors.first("Rear Wiper") }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 col-6">
                        <div>
                          <!-- Comprehensive Navigation System -->
                          <div class="single-input">
                            <label for="account-details-lastname8">
                              Navigation System</label
                            >
                            <label
                              style="display: inline-block; margin-right: 12px"
                              class="radio is-inline"
                              v-for="comprehensiveNavigationSystemState in radioButton"
                              :key="comprehensiveNavigationSystemState.id"
                            >
                              <input
                                style="margin-top: 0px; height: 25px"
                                type="radio"
                                :value="comprehensiveNavigationSystemState.id"
                                class="radio-input"
                                name="Navigation System"
                                v-model="
                                  vehiclenfo.comprehensiveNavigationSystemState
                                "
                              />
                              <span class="radio-label">{{
                                comprehensiveNavigationSystemState.name
                              }}</span>
                            </label>
                            <!-- <div class="errorMes">
                              {{
                                errors.first(
                                  "comprehensiveNavigationSystemState"
                                )
                              }}
                            </div> -->
                            <span
                              v-show="errors.has('Navigation System')"
                              class="help is-danger errorMes"
                              >{{ errors.first("Navigation System") }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-3 col-6">
                        <div>
                          <!-- Parking Sensors -->
                          <div class="single-input">
                            <label for="account-details-lastname8">
                              Parking Sensors</label
                            >
                            <label
                              style="display: inline-block; margin-right: 12px"
                              class="radio is-inline"
                              v-for="parkingSensorsState in radioButton"
                              :key="parkingSensorsState.id"
                            >
                              <input
                                style="margin-top: 0px; height: 25px"
                                type="radio"
                                :value="parkingSensorsState.id"
                                class="radio-input"
                                name="Parking Sensors"
                                v-model="vehiclenfo.parkingSensorsState"
                              />
                              <span class="radio-label">{{
                                parkingSensorsState.name
                              }}</span>
                            </label>

                            <span
                              v-show="errors.has('Parking Sensors')"
                              class="help is-danger errorMes"
                              >{{ errors.first("Parking Sensors") }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 col-6">
                        <div>
                          <!-- Power steering -->
                          <div class="single-input">
                            <label for="account-details-lastname8">
                              Power steering</label
                            >
                            <label
                              style="display: inline-block; margin-right: 12px"
                              class="radio is-inline"
                              v-for="powerSteeringState in radioButton"
                              :key="powerSteeringState.id"
                            >
                              <input
                                style="margin-top: 0px; height: 25px"
                                type="radio"
                                :value="powerSteeringState.id"
                                class="radio-input"
                                name="Power steering"
                                v-model="vehiclenfo.powerSteeringState"
                              />
                              <span class="radio-label">{{
                                powerSteeringState.name
                              }}</span>
                            </label>

                            <span
                              v-show="errors.has('Power steering')"
                              class="help is-danger errorMes"
                              >{{ errors.first("Power steering") }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 col-6">
                        <div>
                          <!-- Music system -->
                          <div class="single-input">
                            <label for="account-details-lastname8">
                              Music system</label
                            >
                            <label
                              style="display: inline-block; margin-right: 12px"
                              class="radio is-inline"
                              v-for="AMFMRadioState in radioButton"
                              :key="AMFMRadioState.id"
                            >
                              <input
                                style="margin-top: 0px; height: 25px"
                                type="radio"
                                :value="AMFMRadioState.id"
                                class="radio-input"
                                name="Music system"
                                v-model="vehiclenfo.AMFMRadioState"
                              />
                              <span class="radio-label">{{
                                AMFMRadioState.name
                              }}</span>
                            </label>

                            <span
                              v-show="errors.has('Music system')"
                              class="help is-danger errorMes"
                              >{{ errors.first("Music system") }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 col-6">
                        <div>
                          <!-- Rear Parking Camera -->
                          <div class="single-input">
                            <label for="account-details-lastname8">
                              Rear Parking Camera</label
                            >
                            <label
                              style="display: inline-block; margin-right: 12px"
                              class="radio is-inline"
                              v-for="rearParkingCameraState in radioButton"
                              :key="rearParkingCameraState.id"
                            >
                              <input
                                style="margin-top: 0px; height: 25px"
                                type="radio"
                                :value="rearParkingCameraState.id"
                                class="radio-input"
                                name="Rear Parking Camera"
                                v-model="vehiclenfo.rearParkingCameraState"
                              />
                              <span class="radio-label">{{
                                rearParkingCameraState.name
                              }}</span>
                            </label>

                            <span
                              v-show="errors.has('Rear Parking Camera')"
                              class="help is-danger errorMes"
                              >{{ errors.first("Rear Parking Camera") }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-3 col-6">
                        <div>
                          <!-- Exchange -->
                          <div class="single-input">
                            <label for="account-details-lastname8">
                              Key less entry</label
                            >
                            <label
                              style="display: inline-block; margin-right: 12px"
                              class="radio is-inline"
                              v-for="exchangeState in radioButton"
                              :key="exchangeState.id"
                            >
                              <input
                                style="margin-top: 0px; height: 25px"
                                type="radio"
                                :value="exchangeState.id"
                                class="radio-input"
                                name="Key less entry"
                                v-model="vehiclenfo.exchangeState"
                              />
                              <span class="radio-label">{{
                                exchangeState.name
                              }}</span>
                            </label>

                            <span
                              v-show="errors.has('Key less entry')"
                              class="help is-danger errorMes"
                              >{{ errors.first("Key less entry") }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 col-6">
                        <div>
                          <!-- Remote control -->
                          <div class="single-input single-input-half">
                            <label for="account-details-lastname8">
                              Remote control</label
                            >
                            <label
                              style="display: inline-block; margin-right: 12px"
                              class="radio is-inline"
                              v-for="financeState in radioButton"
                              :key="financeState.id"
                            >
                              <input
                                style="margin-top: 0px; height: 25px"
                                type="radio"
                                :value="financeState.id"
                                class="radio-input"
                                name="Remote control"
                                v-model="vehiclenfo.financeState"
                              />
                              <span class="radio-label">{{
                                financeState.name
                              }}</span>
                            </label>

                            <span
                              v-show="errors.has('Remote control')"
                              class="help is-danger errorMes"
                              >{{ errors.first("Remote control") }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 col-6">
                        <!-- Service History -->
                        <div class="single-input">
                          <label for="account-details-lastname8">
                            Service History
                            <span style="color: red">*</span>
                          </label>
                          <label
                            style="display: inline-block; margin-right: 12px"
                            class="radio is-inline"
                            v-for="serviceHistoryState in radioButton"
                            :key="serviceHistoryState.id"
                          >
                            <input
                              style="margin-top: 0px; height: 25px"
                              type="radio"
                              :value="serviceHistoryState.id"
                              class="radio-input"
                              v-validate="'required'"
                              name="Service History"
                              v-model="vehiclenfo.serviceHistoryState"
                            />
                            <span class="radio-label">{{
                              serviceHistoryState.name
                            }}</span>
                          </label>

                          <span
                            v-show="errors.has('Service History')"
                            class="help is-danger errorMes"
                            >{{ errors.first("Service History") }}</span
                          >
                        </div>
                      </div>
                      <div class="col-md-3 col-6">
                        <div>
                          <!-- Sunroof-->
                          <div class="single-input">
                            <label for="account-details-lastname8">
                              Sunroof</label
                            >
                            <label
                              style="display: inline-block; margin-right: 12px"
                              class="radio is-inline"
                              v-for="sunroofSate in radioButton"
                              :key="sunroofSate.id"
                            >
                              <input
                                style="margin-top: 0px; height: 25px"
                                type="radio"
                                :value="sunroofSate.id"
                                class="radio-input"
                                name="Sunroof"
                                v-model="vehiclenfo.sunroofSate"
                              />
                              <span class="radio-label">{{
                                sunroofSate.name
                              }}</span>
                            </label>

                            <span
                              v-show="errors.has('Sunroof')"
                              class="help is-danger errorMes"
                              >{{ errors.first("Sunroof") }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-3 col-6">
                        <div>
                          <!-- Cruise control Compatibility -->
                          <div class="single-input">
                            <label for="account-details-lastname8">
                              Cruise control</label
                            >
                            <label
                              style="display: inline-block; margin-right: 12px"
                              class="radio is-inline"
                              v-for="usebSate in radioButton"
                              :key="usebSate.id"
                            >
                              <input
                                style="margin-top: 0px; height: 25px"
                                type="radio"
                                :value="usebSate.id"
                                class="radio-input"
                                name="Cruise control"
                                v-model="vehiclenfo.usebSate"
                              />
                              <span class="radio-label">{{
                                usebSate.name
                              }}</span>
                            </label>

                            <span
                              v-show="errors.has('Cruise control')"
                              class="help is-danger errorMes"
                              >{{ errors.first("Cruise control") }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 col-6">
                        <div>
                          <div class="single-input">
                            <label for="account-details-lastname8"
                              >Warranty</label
                            >
                            <label
                              style="display: inline-block; margin-right: 12px"
                              class="radio is-inline"
                              v-for="WarrantyState in radioButton"
                              :key="WarrantyState.id"
                            >
                              <input
                                style="margin-top: 0px; height: 25px"
                                type="radio"
                                :value="WarrantyState.id"
                                class="radio-input"
                                name="Warranty"
                                v-model="vehiclenfo.WarrantyState"
                              />
                              <span class="radio-label">{{
                                WarrantyState.name
                              }}</span>
                            </label>

                            <span
                              v-show="errors.has('Warranty')"
                              class="help is-danger errorMes"
                              >{{ errors.first("Warranty") }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 col-6">
                        <!-- Vehicle Certified -->
                        <div class="single-input">
                          <label for="account-details-lastname8">
                            Vehicle Certified</label
                          >
                          <label
                            style="display: inline-block; margin-right: 12px"
                            class="radio is-inline"
                            v-for="vehicleCertifiedState in radioButton"
                            :key="vehicleCertifiedState.id"
                          >
                            <input
                              style="margin-top: 0px"
                              type="radio"
                              :value="vehicleCertifiedState.id"
                              class="radio-input"
                              name="Vehicle Certified"
                              v-model="vehiclenfo.vehicleCertifiedState"
                            />
                            <span class="radio-label">{{
                              vehicleCertifiedState.name
                            }}</span>
                          </label>

                          <span
                            v-show="errors.has('Vehicle Certified')"
                            class="help is-danger errorMes"
                            >{{ errors.first("Vehicle Certified") }}</span
                          >
                        </div>
                      </div>

                      <div class="col-md-3 col-6">
                        <!-- AC -->
                        <div class="single-input">
                          <label for="account-details-lastname8"> AC</label>
                          <label
                            style="display: inline-block; margin-right: 12px"
                            class="radio is-inline"
                            v-for="ac in radioButton"
                            :key="ac.id"
                          >
                            <input
                              style="margin-top: 0px"
                              type="radio"
                              :value="ac.id"
                              class="radio-input"
                              name="AC"
                              v-model="vehiclenfo.ac"
                            />
                            <span class="radio-label">{{ ac.name }}</span>
                          </label>

                          <span
                            v-show="errors.has('AC')"
                            class="help is-danger errorMes"
                            >{{ errors.first("AC") }}</span
                          >
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="single-input">
                          <label>Vehicle History(PDF/Image)</label>
                          <input
                            class="form-control historyImagesCls"
                            type="file"
                            name="historyImages"
                            accept="image/png,  application/pdf, image/jpeg"
                            id="imageVeHisinput"
                            @change="onFileVehicleHistoryChange"
                          />
                          <label for="account-details-lastnam"
                            >Dealer notes
                          </label>
                          <textarea
                            name="Dealer notes"
                            id=""
                            style="width: 100%; height: 80px"
                            v-model="vehiclenfo.vehdescription"
                            placeholder="Dealer notes"
                          >
                          </textarea>
                          <!-- <label for="errorMes" class="errorMes">{{
                            descriptionmessage
                          }}</label> -->
                          <span
                            v-show="errors.has('Dealer notes')"
                            class="help is-danger errorMes"
                            >{{ errors.first("Dealer notes") }}</span
                          >
                        </div>
                      </div>
                    </div>

                    <!-- <div class="row imgUploadVehicle">
                      <div class="" style="    width: 100%;">
                        <div class="control-group" id="fields">
                          <label class="control-label" for="field1">
                            Upload Images <span style="color: red;">*</span>  
                          </label>
                          <div class="controls" style="">
                            <div class="entry input-group upload-input-group">
                              <input
                                class="form-control imageinputCls"
                                type="file"
                                name="Images"
                                multiple
                                accept="image/*"
                                id="imageinput"
                                @change="onFileChange"
                                v-validate="'required'"
                              />
                            </div>

                            <span
                              v-show="errors.has('Images')"
                              class="help is-danger errorMes"
                              >{{ errors.first("Images") }}</span
                            >
                            <span
                              class="help is-danger errorMes"
                              v-if="errorImageCountMsg && !errors.has('Images')"
                              >{{ errorImageCountMsg }}
                            </span>

                            <div></div>
                            <div
                              v-if="images"
                              style="
                                display: flex;
                                flex-wrap: wrap;
                                gap: 10px 10px;
                              "
                              class="imagePreview"
                            >
                              <div
                                v-for="(image, index) in images"
                                :key="index"
                              >
                                <img
                                  :src="image"
                                  class="card"
                                  style="width: 190px; height: 190px"
                                />
                              </div>
                            </div>
                            <div class="btnRmimages">
                              <a @click="resetImage">Clear All</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> -->

                    <!-- <div class="row imgUploadVehicle">
                      <div style="width: 100%">
                        <div class="control-group" id="fields" v-if="images">
                          <label class="control-label" for="field1">
                            Upload Images <span style="color: red">*</span>
                          </label>
                          <div class="controls">
                            <div class="entry input-group upload-input-group">
                              <input
                                class="form-control imageinputCls"
                                type="file"
                                name="Images"
                                multiple
                                accept="image/*"
                                id="imageinput"
                                @change="onFileChange"
                                v-validate="'required'"
                              />
                            </div>

                            <span
                              v-show="errors.has('Images')"
                              class="help is-danger errorMes"
                              >{{ errors.first("Images") }}</span
                            >
                            <span
                              class="help is-danger errorMes"
                              v-if="errorImageCountMsg && !errors.has('Images')"
                              >{{ errorImageCountMsg }}</span
                            >

                            <div class="imageContainer">
                              <div
                                v-for="(image, index) in images"
                                :key="index"
                                class="imagePreview"
                              >
                                <div>
                                  <img
                                    :src="image"
                                    class="card"
                                    style="width: 190px; height: 190px"
                                  />
                                </div>
                                <div>
                                  <label>
                                    <input
                                      type="radio"
                                      v-model="defaultImage"
                                      :value="index"
                                    />
                                    Set as Default
                                  </label>
                                  <button
                                    class="btn btn-danger"
                                    @click="removeImage(index)"
                                  >
                                    Remove
                                  </button>
                                </div>
                              </div>
                            </div>

                            <div class="btnRmimages">
                              <a @click="resetImage">Clear All</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> -->
                    <!-- <div class="vinDecodedDetailsImage">
                      <label for="vehicle-image" class="header-title-m"
                        >Vehicle Images</label
                      >
                      <div class="vehicleImages card-cust-space">
                        <div
                          ref="sectionVeh"
                          class="sectionVeh"
                          id="allImagesId"
                        ></div>

                        <div class="row">
                          <div class="col-md-12" style="display: inherit">
                            <div>
                              <label for="image-input">Upload Images: </label>
                              <input
                                type="file"
                                id="image-input"
                                ref="imageInput"
                                @change="handleImageUpload"
                                multiple
                                accept="image/jpeg"
                              />
                              <label v-if="images.length">Preview</label>
                              <div v-if="images.length" class="row">
                                <div
                                  v-for="(image, index) in images"
                                  :key="index"
                                  class="image-preview col-md-4"
                                >
                                  <img :src="image" alt="Image Preview" />

                                  <div class="image-actions">
                                    <input
                                      type="radio"
                                      v-model="defaultImageIndex"
                                      :value="index"
                                    />
                                    Set as Default
                                    <button @click="handleImageDelete(index)">
                                      <i
                                        class="fa fa-trash"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div class="erroMessage" v-if="imageError">
                                {{ imageErrorMessage }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> -->

                    <template>
                      <label style="margin-top:10px">Upload Images</label>
                      <VueFileAgent
                        ref="vueFileAgent"
                        :theme="'grid'"
                        :multiple="true"
                        :deletable="true"
                        :meta="true"
                        :accept="'image/*'"
                        :maxSize="'2MB'"
                        :maxFiles="30"
                        :helpText="'Choose images '"
                        :sortable="'handle'"
                        :errorText="{
                          type: 'Invalid file type. Only images Allowed',
                          size: 'Files should not exceed 2MB in size',
                        }"
                        @select="filesSelected($event)"
                        @beforedelete="onBeforeDelete($event)"
                        @delete="fileDeleted($event)"
                        v-model="fileRecords"
                      ></VueFileAgent>
                      <p>Upload {{ fileRecordsForUpload.length }} files</p>
                    </template>
                    <span
                      class="help is-danger errorMes"
                      v-if="errorImageCountMsg && !errors.has('Images')"
                      >{{ errorImageCountMsg }}
                    </span>
                    <div class="single-input" style="margin-top: 0px">
                      <button
                        class="uren-btn save-changes"
                        type="submit"
                        :disabled="loading"
                      >
                        <span
                          v-show="loading"
                          class="spinner-border spinner-border-sm"
                        ></span>
                        <span>SAVE</span>
                      </button>
                    </div>
                    <div></div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import userService from "@/services/user.service";
import VehDetails from "@/models/vehDetails";
// import { Validator } from "vee-validate";
export default {

  data() {
    return {
      isLoading:true,
      loading: false,
      currentuserinfo: "",
      message: "",
      statemessage: "",
      citymessage: "",
      districtmessage: "",
      imagesmessage: "",
      disabled: 0,
      vehiclenfo: new VehDetails("", "", "", "", "", "", "", "", "", ""),
      images: [],
      imagesFile: [],
      imagesVeh: [],
      imagesFileVeh: [],
      States: "",
      st: [],
      Districts: "",
      City: "",
      ct: "",
      sellerNamemessage: "",
      sellerEmailmessage: "",
      sellerPhonemessage: "",
      radioButton: [
        { name: "Yes", id: true },
        { name: "No", id: false },
      ],
      pricemessage: "",
      VechTypemessage: "",
      VechVariantemessage: "",
      Vechinsurancemessage: "",
      VechBrandmessage: "",
      VechModelmessage: "",
      descriptionmessage: "",
      VechType: [],
      VechBrand: [],
      VechModel: [],
      VechVariant: [],
      InsuranceType: [],
      FuelType: [],
      errorImageCountMsg: "",
      isImageCountValid: "",
      imageLength: 0,
      defaultImage: "",
      vehicleImages: [],
      defaultImageIndex: 0,
      fileRecords: [],
      fileRecordsForUpload: [], // maintain an upload queue      
     };
  },

  methods: {
    uploadFiles: function () {
      // Using the default uploader. You may use another uploader instead.
      //this.$refs.vueFileAgent.upload(this.uploadUrl, this.uploadHeaders, this.fileRecordsForUpload);

      ///console.log("File uploaded ", this.fileRecordsForUpload);

      this.fileRecordsForUpload = [];
    },
    deleteUploadedFile: function (fileRecord) {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.deleteUpload(
        this.uploadUrl,
        this.uploadHeaders,
        fileRecord
      );
    },
    filesSelected: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      this.fileRecordsForUpload =
        this.fileRecordsForUpload.concat(validFileRecords);

      this.checkImageCount(this.fileRecordsForUpload.length);
    },
    onBeforeDelete: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUpload.splice(i, 1);
        var k = this.fileRecords.indexOf(fileRecord);
        if (k !== -1) this.fileRecords.splice(k, 1);
      } else {
        if (confirm("Are you sure you want to delete?")) {
          this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
        }
      }
    },
    fileDeleted: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        this.deleteUploadedFile(fileRecord);
      }
    },
    // async handleImageUpload(event) {
    //   const curFiles = event.target.files;

    //   for (const file of curFiles) {
    //     if (file.size <= 2000000) {
    //       this.isImageValid = true;
    //       this.imageError = false;

    //       const base64Url = await this.readFileAsDataURL(file);
    //       this.images.push(base64Url);
    //     } else {
    //       this.isImageValid = false;
    //       this.imageError = true;
    //       this.imageErrorMessage = "Image size must be less than 2MB";
    //     }
    //   }

    //   if (this.images.length < 4) {
    //     this.isImageValid = false;
    //     this.imageError = true;
    //     this.imageErrorMessage = "Need atleast 4  image for upload";
    //   } else if (this.images.length > 30) {
    //     this.isImageValid = false;
    //     this.imageError = true;
    //     this.imageErrorMessage = "Maximum 30 images allowed for upload";
    //   }
    // },

    readFileAsDataURL(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
          const base64Url = reader.result;
          resolve(base64Url);
        };

        reader.onerror = (error) => {
          reject(error);
        };

        reader.readAsDataURL(file);
      });
    },

    handleImageDelete(index) {
      this.images.splice(index, 1);
      this.imageList = this.images;
    },

    clearAllFields() {
      if (this.st.vehmake == "" || this.st.vehmake == null) {
        this.st.vehmodel = "";
        this.st.vehVariant = "";
      }
    },
    clearVarientFields() {
      if (this.st.vehmodel == "" || this.st.vehmodel == null) {
        this.st.vehVariant = "";
      }
    },
    getModelList(make) {
      this.st.vehmake = make;
      this.getVehicleModel(make);
    },
    getVariantList(model) {
      this.st.vehmodel = model;
      this.getVehicleVariant(model);
    },
    getVariantFinalList(variant) {
      this.st.vehVariant = variant;
    },
    getVehicleTypes() {
      userService
        .getVehicleTypes()
        .then((response) => {
          this.VechType = response.data.Data;
          // console.log(this.VechType);
        })
        .catch((e) => {
          console.log(e);
          // this.$router.push("/Auction").catch(() => {
          //   "details/auctionbyname";
          // });
        });
    },
    getVehicleFuelType() {
      userService
        .getVehicleFuelType()
        .then((response) => {
          this.FuelType = response.data.Data;
          //console.log(this.FuelType);
        })
        .catch((e) => {
          console.log(e);
          // this.$router.push("/Auction").catch(() => {
          //   "details/auctionbyname";
          // });
        });
    },
    getVehicleBrand() {
      userService
        .getVehicleBrand()
        .then((response) => {
          this.VechBrand = response.data.Data;
          // console.log(this.VechBrand);
        })
        .catch((e) => {
          console.log(e);
          // this.$router.push("/Auction").catch(() => {
          //   "details/auctionbyname";
          // });
        });
    },
    getVehicleModel(make) {
      userService
        .getVehicleModel(make)
        .then((response) => {
          this.VechModel = response.data.Data;
          // console.log(this.VechModel);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    getVehicleVariant(model) {
      userService
        .getVehicleVariant(model)
        .then((response) => {
          this.VechVariant = response.data.Data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getInsuranceTypes() {
      userService
        .getInsuranceTypes()
        .then((response) => {
          this.InsuranceType = response.data.Data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    onFileVehicleHistoryChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createVehicleHistoryImage(files);
    },
    createVehicleHistoryImage(files) {
      var vm = this;

      for (var index = 0; index < files.length; index++) {
        var reader = new FileReader();
        reader.onload = function (event) {
          const imageUrl = event.target.result;
          vm.imagesVeh.push(imageUrl);
          vm.imagesFileVeh.push(imageUrl);
        };

        reader.readAsDataURL(files[index]);
      }
    },

    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files);
    },
    createImage(files) {
      var vm = this;
      for (var index = 0; index < files.length; index++) {
        var reader = new FileReader();
        reader.onload = function (event) {
          const imageUrl = event.target.result;
          vm.images.push(imageUrl);
          vm.imagesFile.push(imageUrl);
        };
        reader.readAsDataURL(files[index]);
      }
      var array = [];
      array.push(vm.imagesFile);

      // console.log("onFileChange Image length", vm.images);
      // console.log("onFileChange array Image length", array);
      // this.checkImageCount(vm.images.length)
    },
    // removeImage(index) {
    //   // If the removed image was set as default, reset defaultImage
    //   if (this.defaultImage === index) {
    //     this.defaultImage = null;
    //   }

    //   this.images.splice(index, 1);
    //   //var es = document.getElementsByClassName("imageinputCls");

    //   // var imageFile = [];
    //   // imageFile = es[0].files;
    //   // console.log("image file ", imageFile.length);

    //   // console.log("image List", this.images.length);
    //   // console.log("onFileChange Image length", this.imageLength);
    //   // this.checkImageCount(imageCount)
    // },
    resetImage() {
      console.log("reset img");
      var es = document.getElementsByClassName("imageinputCls");
      es[0].value = "";
      this.images = [];
      this.imageLength = 0;
      this.checkImageCount(0);
    },
    checkImageCount(imageCount) {
      //imageCount="";

      if (imageCount <= 3) {
        this.errorImageCountMsg = "A minimum of 4  images are required.";
        this.isImageCountValid = false;
      } else if (imageCount > 30) {
        this.errorImageCountMsg = "Can't upload more than 30 images.";
        this.isImageCountValid = false;
      } else {
        this.errorImageCountMsg = "";
        this.isImageCountValid = true;
      }
    },
    addvechicle() {
      this.loading = true;

      let imageList = [];

      for (let index = 0; index < this.fileRecordsForUpload.length; index++) {
        const value = this.fileRecordsForUpload[index].urlResized;

        imageList.push(value);
      }
      ///console.log("image count",this.fileRecordsForUpload.length);
      this.checkImageCount(this.fileRecordsForUpload.length);

      this.$validator.validateAll().then((result) => {
        ///  console.log("isImageCountValid",this.isImageCountValid);
        if (result && this.isImageCountValid != false) {

          //console.log("add vehicle page",  this.vehiclenfo);
         // console.log("add vehicle page st",  this.st);
          userService
            .addVeh(
              this.st,
              this.vehiclenfo,
              imageList,
              this.imagesVeh[0],
              this.defaultImageIndex
            )
            .then((response) => {
              this.currentuserinfo = response.data.Data[0];
              this.message = response.data.Message;
              this.fileRecordsForUpload = [];
              // this.message = "Vehicle successfully Uploaded";
              this.makeToast("success", "Vehicle Successfully Uploaded");
              var self = this;
              setTimeout(function () {
                self.$router.push("/MyVehicleListings").catch(() => {
                  "MyVehicleListings";
                });
              }, 4000);
            })
            .catch((e) => {
              this.loading = false;
              this.message = e.response.data.Message;
              this.makeToast("danger", this.message);
            });
        } else {
          this.loading = false;
        }
      });
    },

    makeToast(variant, body_content) {
      let tittleMessage = variant;
      if (variant == "danger") {
        tittleMessage = "Error";
      }
      if (variant == "success") {
        tittleMessage = "Success";
      }
      if (variant == "warning") {
        tittleMessage = "Warning";
      }
      this.$bvToast.toast(body_content, {
        title: `${tittleMessage || "default"}`,
        variant: variant,
        autoHideDelay: 5000,
        //solid: true,
        toaster: "b-toaster-bottom-right",
        appendToast: true,
      });
    },
    getAllStates() {
      let id = this.st.sellerId;
      if (id == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getStatebyLocId()
          .then((response) => {
            this.States = response.data.Data;
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    getDistrictbyLocId(event) {
      let id = this.st.sellerId;
      let statename = event.target.value;
      if (id == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getDistrictbyLocId(statename)
          .then((response) => {
            this.Districts = response.data.Data;
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    getLocationByStateId(event) {
      let id = this.st.sellerId;
      let district = event.target.value;
      if (id == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getLocationByStateId(district)
          .then((response) => {
            this.City = response.data.Data;
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    async isPackageActive(id) {
      if (id === "undefined") {
        console.log("undefined");
      } else {
        try {
          const response = await userService.isPackageActive(id);
          
        if(response.data.Data[0].isPackageActive == 1)
        {        
          this.getVehicleBrand();
          this.getVehicleTypes();
          this.getAllStates();
          this.getInsuranceTypes();
          this.getVehicleFuelType();
          this.isLoading =false;
        }
        else {

           this.makeToast('warning', '! Please buy a package to continue')
          setTimeout(() => {
           
            this.$router.push("/packages").catch(() => {
          "packages";
            });
            this.isLoading =false;
          }, 3000);         
        }
        } catch (e) {
          console.log(e);
        }
      }
}
  },
  created() {
    if (this.$store.state.auth.user) {
      if (localStorage.getItem("reloaded")) {
        // The page was just reloaded. Clear the value from local storage
        // so that it will reload the next time this page is visited.
        localStorage.removeItem("reloaded");
      } else {
        // Set a flag so that we know not to reload the page twice.
        localStorage.setItem("reloaded", "1");
        location.reload();
      }
    }
    if (this.loggedIn) {
      if (
        !this.$store.state.auth.user[0].sellerId == "" ||
        !this.$store.state.auth.user[0].sellerId == " "
      ) {
       this.isLoading =true;
       this.isPackageActive(this.$store.state.auth.user[0].sellerId);
       this.st = this.$store.state.auth.user[0];
      } else {
        
        this.$router.push("/").catch(() => {
          "home";
        });
      }
    } 
  },


  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    filterByBrand() {
      return this.VechBrand.filter((item) => {
        if (this.st.vehmake) {
          this.VechBrandmessage = "";
          return item.make
            .toLowerCase()
            .includes(this.st.vehmake.toLowerCase());
        }
      });
    },
    filterByModel() {
      return this.VechModel.filter((item) => {
        if (this.st.vehmodel) {
          this.VechModelmessage = "";
          return item.model
            .toLowerCase()
            .includes(this.st.vehmodel.toLowerCase());
        }
      });
    },
  },
};
</script>
<style scoped>
.uren-form-inner {
  display: block;
}

.uren-form .uren-form-inner .single-input input:first-child,
.uren-form-boxed .uren-form-inner .single-input input:first-child {
  margin-top: 0px;
}

.save-changes:hover {
  background-color: #0094da;
}
.pdtSliderhome {
  background-color: white;
  border-radius: 5px;
  margin: 10px 8px;
  padding: 5px;
}

.btn-upload {
  padding: 10px 20px;
  margin-left: 10px;
}

.input-group > .custom-select:not(:last-child),
.input-group > .form-control:not(:last-child) {
  height: 45px;
}
.imgUploadVehicle {
  margin: 0px;
  width: 100% !important;
}
.btnRmimages {
  display: block;
  width: 105px;
  background: #4e9caf;
  padding: 7px 0px;
  text-align: center;
  border-radius: 2px;
  color: white !important;
  font-weight: bolder;
  cursor: pointer;
  margin-left: 0px;
  margin-top: 12px;
}
.btnRmimages a {
  color: white;
}
.btnRmimages a:hover {
  color: white !important;
}
.vehSelection {
  position: absolute;
  background-color: white;
  width: 100%;
  padding-left: 10px;
  z-index: 99999;
}
.dropdown-item {
  cursor: pointer;
}
/* .dropdown-wrapper , i {
  position: absolute;
   
    margin-left: -6%;
} */
.dropdown-wrapper i {
  position: absolute;
  margin-top: 5%;
  margin-left: -6%;
}
span.help.is-danger.errorMes {
  display: block;
}
.uren-form .uren-form-inner .single-input.single-input-half,
.uren-form-boxed .uren-form-inner .single-input.single-input-half {
  width: 100%;
  max-width: unset;
}
textarea {
  padding: 4px 10px !important;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
  font-size: 14px;
  padding: 3px 3px;
}
label {
  margin-bottom: 2px;
  font-weight: 500;
}
div#fields {
  margin-top: 10px;
}

.imagePreview img {
  margin-top: 10px;
}

@media only screen and (max-width: 768px) {
  button.uren-btn.save-changes {
    width: 100%;
    padding: 4px 10px;
    height: 39px;
    margin-top: 10px;
  }
  .btnRmimages {
    width: 100%;
  }
}

.imageContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
}

.imagePreview {
  flex: 0 0 calc(20% - 10px);
  box-sizing: border-box;
  margin-bottom: 10px;
  text-align: center;
}

.imagePreview img {
  max-width: 100%;
}
</style>
