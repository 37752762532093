<template>
  <div>
    <div>
      <main class="page-content">
        <div class="account-page-area vendordashboard">
          <div class="container containerDash">
            <div class="row">
              <div class="col-lg-3 account_border-right">
                <sidebar-dashboard></sidebar-dashboard>
              </div>

              <!-- ///// -->
              <div class="col-lg-9">
                <h4>Won Customers List</h4>

                <div v-if="!customerList.length">
                  <notfound :checkdata="initialLoading"></notfound>
                </div>
                <div v-else-if="customerList.length">
                  <div class="noPrint">
                    <button
                      style="
                        float: right;
                        margin-bottom: 10px;
                        margin-right: 5px;
                      "
                      class="btn btn-primary"
                      @click="printWon"
                    >
                      Print
                    </button>
                  </div>
                  <section
                    style="background-color: #f1f1f1; margin-top: 20px"
                    class="autionlog-table"
                  >
                    <div class="table-wrapper">
                      <div class="table-cont">
                        <div class="rowz headerzz">
                          <div class="cellz">Id</div>
                          <div class="cellz">Auction Name</div>
                          <div class="cellz">Customer</div>
                          <div class="cellz">Bid Amount</div>
                          <div class="cellz">Balance Amount</div>
                          <div class="cellz">Payment Method</div>
                          <div class="cellz"></div>
                        </div>

                        <div
                          class="rowz"
                          v-for="list in customerList"
                          :key="list.aucid"
                        >
                          <div class="cellz" data-title="Id">
                            {{ list.aucid }}
                          </div>
                          <div class="cellz" data-title="Auction Name">
                            {{ list.aucName }}
                          </div>
                          <div class="cellz" data-title="Customer">
                            {{ list.custName }}
                          </div>
                          <div class="cellz" data-title="Bid Amount">
                            {{ list.latestBidAmount | currency }}
                          </div>

                          <div
                            v-if="
                              list.saleStatus == 'SaleConfirm' &&
                              list.paymentMode != null
                            "
                            class="cellz btn btn-success"
                            data-title="Balance Amount"
                            style="cursor: no-drop"
                          >
                            <i class="fa fa-check-square"></i> Paid
                          </div>
                          <div v-else class="cellz" data-title="Balance Amount">
                            {{ list.balance | currency }}
                          </div>

                          <div class="cellz" data-title="Payment Method">
                            <select
                              class="form-control"
                              v-model="list.selected"
                              v-if="!list.saleStatus && list.otpStatus == true"
                            >
                              <option selected value="" disabled>
                                Select a payment mode
                              </option>
                              <option>Cash/Online</option>
                              <option>Finance</option>
                            </select>
                            <div v-else-if="list.saleStatus == 'SaleConfirm'">
                              {{ list.paymentMode }}
                            </div>
                          </div>
                          <div class="cellz" data-title="">
                            <div
                              v-if="
                                list.saleStatus == 'SaleConfirm' // &&
                                //list.ReviewID !== null
                              "
                            >
                              <button
                                class="btn btn-success"
                                style="cursor: no-drop"
                              >
                                <i class="fa fa-check-square"></i> Sold
                              </button>
                            </div>
                            <div
                              v-else-if="
                                //list.saleStatus == 'SaleConfirm' &&
                                list.ReviewID == null && 1 ==2
                              "
                              class="cellz"
                              data-title=""
                            >
                              <button
                                class="btn btn-primary"
                                @click="showSellerModal = true"
                              >
                                Add Review
                              </button>
                              <div>
                                <b-modal
                                  v-model="showSellerModal"
                                  title="Add Seller Review"
                                  hide-footer
                                  @hide="onModalHide"
                                >
                                  <div class="modal-body">
                                    <h5>
                                      <strong>{{ list.aucName }}</strong>
                                    </h5>
                                    <div class="rating">
                                      <label>Rating:</label>
                                      <vue-star-rating
                                        v-model="rating"
                                        :star-size="25"
                                      ></vue-star-rating>
                                    </div>
                                    <div class="form-group">
                                      <label>Comment:</label>
                                      <textarea
                                        v-model="comment"
                                        class="form-control"
                                        rows="3"
                                      ></textarea>
                                    </div>
                                    <button
                                      @click="submitReview(list.aucid)"
                                      class="btn btn-primary mt-3"
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </b-modal>
                              </div>
                            </div>
                            <div v-else-if="list.saleStatus == 'Rejected'">
                              <button
                                class="btn btn-danger"
                                style="cursor: no-drop"
                                disabled
                              >
                                Rejected
                              </button>
                            </div>
                            <div
                              v-else-if="
                                !list.saleStatus && list.otpStatus == true
                              "
                            >
                              <button
                                class="btn btn-success"
                                @click="confirmSale(list)"
                              >
                                Confirm Sale
                              </button>
                            </div>
                            <div v-else style="" class="row">
                              <div class="col-8" style="">
                                <input
                                  type="number"
                                  class="form-control"
                                  v-model="list.otp"
                                  name="OTP"
                                  placeholder="OTP"
                                />
                              </div>

                              <div class="col-2" style="">
                                <button
                                  @click="vrifyCustomer(list)"
                                  class="btn btn-warning"
                                >
                                  Verify
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>

              <!-- ///// -->
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import userService from "@/services/user.service";
// import Config from "@/config";
import Config from "../../config";
import VueStarRating from "vue-star-rating";

export default {
  data() {
    return {
      customerList: "",
      path: Config.BASE_URL_IMG,
      initialLoading: true,
      vrify_Customer: false,
      otp: "",
      message: "",
      Salemessage: "",
      toastCount: 0,
      showSellerModal: false,
      rating: 0,
      comment: "",
    };
  },
  components: {
    "vue-star-rating": VueStarRating,
  },
  mounted() {
    if (this.$store.state.auth.user) {
      this.st = this.$store.state.auth.user[0];

      this.getWonCustomerList(this.st.sellerId);
    } else {
      this.$router.push("/").catch(() => {
        "myaccount";
      });
    }
  },
  methods: {
    onModalHide() {
      this.clearModalData();
    },
    submitReview(aucid) {
      // Handle save for seller
      this.isLoading = true;

      userService
        .addUserReview(
          "INS",
          aucid,
          this.comment,
          this.rating,
          this.st.sellerId,
          "Seller"
        )
        .then((response) => {
          this.Salemessage = response.data.Message;
          if (this.Salemessage == "Success") {
            this.clearModalData();
            this.makeToast("success", "Updated successfully");
            setTimeout(function () {
              location.reload();
            }, Config.pageRedirectDelay * 1000);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.message =
            (error.response && error.response.data.Message) ||
            error.message ||
            error.toString();

          this.makeToast("warning", this.message);
        });
    },
    clearModalData() {
      // Clear form fields when modal is closed
      this.rating = 0;
      this.comment = "";
      this.showSellerModal = false;
    },
    makeToast(variant, body_content) {
      let tittleMessage = variant;
      if (variant == "danger") {
        tittleMessage = "Error";
      }
      if (variant == "success") {
        tittleMessage = "Success";
      }
      if (variant == "warning") {
        tittleMessage = "Warning";
      }
      this.$bvToast.toast(body_content, {
        title: `${tittleMessage || "default"}`,
        variant: variant,
        autoHideDelay: 5000,
        //solid: true,
        toaster: "b-toaster-bottom-right",
        appendToast: true,
      });
    },

    printWon() {
      window.print();
    },
    confirmSale(data) {
      if (!data.selected) {
        // alert();
        this.makeToast("warning", "Chose a payment method");
        // this.Salemessage = "Chose a payment method";
      } else {
        userService
          .updVendorSaleStatus(this.st.sellerId, data)
          .then((response) => {
            this.Salemessage = response.data.Message;
            if (this.Salemessage == "Success") {
              // var self = this;
              this.makeToast("success", "Updated successfully");
              setTimeout(function () {
                location.reload();
              }, Config.pageRedirectDelay * 1000);
            }
          })
          .catch((error) => {
            this.isLoading = false;
            this.message =
              (error.response && error.response.data.Message) ||
              error.message ||
              error.toString();

            this.makeToast("warning", this.message);
          });
      }
    },
    vrifyCustomer(list) {
      this.message = "";
      if (list.otp > 0 && list.otp.length > 4) {
        userService
          .vrifyCustomer(list, this.st.sellerId)
          .then((response) => {
            this.message = response.data.Message;
            if (this.message == "Success") {
              this.makeToast("success", "OTP verified successfully");
              //
              setTimeout(() => {
                location.reload();
              }, Config.pageRedirectDelay * 1000);
            }
          })
          .catch((e) => {
            if (
              e.response.data.Message == "Unauthorized Access. Please Login"
            ) {
              this.makeToast("warning", "Please enter a valid otp");
            } else {
              this.makeToast("warning", "Somthing went wrong");
            }
          });
      } else {
        this.message = "Please enter a valid otp";
        this.makeToast("warning", this.message);
      }
    },
    getWonCustomerList(sellerId) {
      // console.log(sellerId);
      if (sellerId == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getWonCustomersAgainstSellerAuctions(sellerId)
          .then((response) => {
            this.customerList = response.data.Data;
            this.initialLoading = false;

            // console.log(this.customerList);
          })
          .catch((e) => {
            console.log(e);
            this.initialLoading = false;
          });
      }
    },
  },
  created() {
    if (this.loggedIn) {
      if (
        !this.$store.state.auth.user[0].sellerId == "" ||
        !this.$store.state.auth.user[0].sellerId == " "
      ) {
        this.$router.push("/WonCustomer").catch(() => {
          "home";
        });
      } else {
        this.$router.push("/").catch(() => {
          "home";
        });
      }
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  filters: {
    currency: function (value) {
      if (typeof value !== "number") {
        return value;
      }
      let country = Config.countryCode;
      let crncy = Config.curency;
      let FractionDigits = Config.minFractionDigits;
      const formatter = new Intl.NumberFormat(country, {
        style: "currency",
        currency: crncy,
        minimumFractionDigits: FractionDigits,
      });
      return formatter.format(value);
    },
  },
};
</script>
<style scoped>
.verifyClass .alert-danger {
  padding: 3px;
  width: 100px;
}

th label {
  margin-bottom: 0px;
}

table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}

table tr {
  background-color: #fff;
  border: 1px solid #e1e5e9;
  padding: 0.35em;
  border-radius: 3px;
}

table thead tr:first-child {
  border: 1px solid #0094da;
}

table th,
table td {
  padding: 8px;
  text-align: left;
  color: #202020;
  font-size: 14px;
}

table td:nth-child(4) {
  font-size: 18px;
}

table th {
  font-size: 0.85em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  background: #0094da;
  color: #fff;
}

table tbody tr td .btn-invoice {
  background: #0094da;
  color: #fff;
  font-size: 13px;
  padding: 10px 20px;
  border: 0;
  width: 100%;
  border-radius: 5px;
}

tbody tr:nth-child(even) {
  background-color: #eee;
}

tbody tr:nth-child(odd) {
  background-color: #fff;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    position: absolute;
    width: 1px;
    padding: 0;
  }

  table tr {
    border-bottom: 3px solid #e1e5e9;
    display: block;
    margin-bottom: 0.625em;
  }

  table th,
  table td {
    padding: 0.625em;
  }

  table td {
    border-bottom: 1px solid #e1e5e9;
    display: block;
    font-size: 0.8em;
    text-align: right;
    color: #9da9b9;
  }

  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
    color: #656971;
  }

  table td:last-child {
    border-bottom: 0;
  }

  table td:nth-child(4) {
    font-size: 0.8em;
  }
}

@media only screen and (max-width: 660px) {
  .btn-success {
    /* color: #fff; */
    background-color: unset !important;
    border: 0px !important;
    color: GREEN !important;
  }
}
</style>
