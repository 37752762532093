<template>
  <div>
    <div v-if="!items.length">
      <div style="text-align: center">
        <a href="/" class="go-to-h cust-pos-el"
          ><i class="fa fa-arrow-left" aria-hidden="true"></i> Go Back</a
        >
      </div>
      <notfound :checkdata="initialLoading"></notfound>
    </div>
    <div v-else-if="items.length">
      <section style="padding: 15px 0px" class="">
        <div>
          <!-- <div class="tab-content">
          <div class="tab-pane active" id="tabs-1" role="tabpanel"> -->
          <div>
            <!-- Filter Mode -->
            <div>
              <section class="section size" style="margin-bottom: 15px">
                <div class="container vehicle-Filter vehicle-filter-close">
                  <div
                    class="row"
                    style="background-color: #e5e5e5; border-radius: 5px"
                  >
                    <div class="col-md-2 filter-col">
                      <div>
                        <div>
                          <a
                            class="filter-name"
                            @click="isActiveBrand = !isActiveBrand"
                          >
                            Vehicle Brand
                            <span class="c-down" v-if="!isActiveBrand"
                              ><i
                                class="fa fa-caret-down"
                                aria-hidden="true"
                              ></i
                            ></span>
                            <span v-else>
                              <i class="fa fa-caret-up" aria-hidden="true"></i
                            ></span>
                          </a>
                          <div
                            class="filter-dropdown-class"
                            v-if="isActiveBrand == true"
                          >
                            <div
                              v-for="(brand, index) in uniqueBrand"
                              :key="index"
                            >
                              <label>
                                <input
                                  type="checkbox"
                                  v-model="veBrand"
                                  :value="brand.brandName"
                                /><span class="checkbox-material"
                                  ><span class="check"></span
                                ></span>
                                <span> {{ brand.brandName }}</span>
                              </label>
                            </div>
                          </div>
                          <!-- <p  v-for="(nameofbrand,index ) in veBrand" :key="index">{{nameofbrand}}</p> -->
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 filter-col">
                      <div>
                        <div>
                          <a
                            class="filter-name"
                            @click="isActiveModal = !isActiveModal"
                          >
                            Vehicle Model
                            <span class="c-down" v-if="!isActiveModal"
                              ><i
                                class="fa fa-caret-down"
                                aria-hidden="true"
                              ></i
                            ></span>
                            <span v-else>
                              <i class="fa fa-caret-up" aria-hidden="true"></i
                            ></span>
                          </a>
                          <div
                            class="filter-dropdown-class"
                            v-if="isActiveModal == true"
                          >
                            <div
                              v-for="(model, index) in uniqueModel"
                              :key="index"
                            >
                              <label>
                                <input
                                  type="checkbox"
                                  v-model="veModel"
                                  :value="model.modelName"
                                /><span class="checkbox-material"
                                  ><span class="check"></span
                                ></span>
                                <span> {{ model.modelName }}</span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 filter-col">
                      <div>
                        <div>
                          <a
                            class="filter-name"
                            @click="isActiveLocation = !isActiveLocation"
                          >
                            Location
                            <span class="c-down" v-if="!isActiveLocation"
                              ><i
                                class="fa fa-caret-down"
                                aria-hidden="true"
                              ></i
                            ></span>
                            <span v-else>
                              <i class="fa fa-caret-up" aria-hidden="true"></i
                            ></span>
                          </a>
                          <div
                            class="filter-dropdown-class"
                            v-if="isActiveLocation == true"
                          >
                            <div
                              v-for="(location, index) in uniqueLocation"
                              :key="index"
                            >
                              <label>
                                <input
                                  type="checkbox"
                                  v-model="veLocation"
                                  :value="location.locName"
                                /><span class="checkbox-material"
                                  ><span class="check"></span
                                ></span>
                                <span> {{ location.locName }}</span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 filter-col">
                      <div>
                        <div>
                          <a
                            class="filter-name"
                            @click="isActiveKms = !isActiveKms"
                          >
                            Km Clocked
                            <span class="c-down" v-if="!isActiveKms"
                              ><i
                                class="fa fa-caret-down"
                                aria-hidden="true"
                              ></i
                            ></span>
                            <span v-else>
                              <i class="fa fa-caret-up" aria-hidden="true"></i
                            ></span>
                          </a>
                          <div
                            class="filter-dropdown-class"
                            v-if="isActiveKms == true"
                          >
                            <div
                              v-for="(km, index) in uniqueKmClocked"
                              :key="index"
                            >
                              <label>
                                <input
                                  type="checkbox"
                                  v-model="veKmclocked"
                                  :value="km.kmClocked"
                                /><span class="checkbox-material"
                                  ><span class="check"></span
                                ></span>
                                <span> {{ km.kmClocked }}</span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 filter-col">
                      <div>
                        <div>
                          <a
                            class="filter-name"
                            @click="isActiveYears = !isActiveYears"
                          >
                            Year of Make
                            <span class="c-down" v-if="!isActiveYears"
                              ><i
                                class="fa fa-caret-down"
                                aria-hidden="true"
                              ></i
                            ></span>
                            <span v-else>
                              <i class="fa fa-caret-up" aria-hidden="true"></i
                            ></span>
                          </a>
                          <div
                            class="filter-dropdown-class"
                            v-if="isActiveYears == true"
                          >
                            <div
                              v-for="(
                                manuFacturedDate, index
                              ) in uniqueYearOfMake"
                              :key="index"
                            >
                              <label>
                                <input
                                  type="checkbox"
                                  v-model="veYearofmake"
                                  :value="manuFacturedDate.manYear"
                                /><span class="checkbox-material"
                                  ><span class="check"></span
                                ></span>
                                <span> {{ manuFacturedDate.manYear }}</span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 filter-col">
                      <div>
                        <!-- <button @click="getFilterList()" class="filter-btnz">
                          Apply
                        </button> -->
                      </div>
                      <div></div>

                      <div class="row">
                        <div class="col-6" style="padding: 0px">
                          <div>
                            <button
                              @click="ClearFilter()"
                              class="clearall-btnz"
                            >
                              Clear All
                            </button>
                          </div>
                        </div>
                        <div class="col-6" style="padding: 0px">
                          <div>
                            <button
                              style="margin: 0px"
                              @click="ClearFilter()"
                              class="refresh-btnz"
                            >
                              <span>
                                Refresh
                                <i class="fas fa-redo-alt"></i>
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="">
                    <div>
                      <div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <!-- Filter Mode -->

            <!-- onload -->
            <div class="container bidz-container-list">
              <div v-for="(item, index) in filteredItems" :key="index">
                <!-- Card content -->
                <div class="pdtListingWrap">
                  <div class="row">
                    <div class="col-md-2">
                      <div class="lCustWrapp">
                        <div class="bidlistImagewrapMainW">
                          <div class="bidlistImagewrap ttt">
                            <div class="is-premium-tag" v-if="item.isPremium">
                              <label for="premium">Premium</label>
                            </div>
                            <router-link
                              :to="{
                                name: 'CarDetails',
                                params: { url_key: item.aucName },
                              }"
                            >
                              <img
                                :src="
                                  item.coverImage
                                    ? path + '/' + item.coverImage
                                    : item.vehImage1
                                    ? path + '/' + item.vehImage1
                                    : '/assets/images/NoImage.jpg'
                                "
                              />
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-7 bidborderright">
                      <div class="row">
                        <div class="bidlistDetailswrapwidth85">
                          <div class="bidlistDetailswrap">
                            <router-link
                              :to="{
                                name: 'CarDetails',
                                params: { url_key: item.aucName },
                              }"
                            >
                              <h4>
                                <span>{{ item.manYear }}</span>
                                {{ item.brandName }}
                                <span class="cModalname">
                                  {{ item.modelName }}
                                </span>
                              </h4>
                            </router-link>
                          </div>
                        </div>
                        <div class="bidlistDetailswrapwidth15">
                          <div v-if="item.isWishlisted == 1">
                            <div
                              class="button-bidzzz active"
                              @click="rmvfromWhishlist(item)"
                            >
                              <svg
                                width="35px"
                                height="25px"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fill-rule="evenodd">
                                  <path
                                    class="heart-stroke"
                                    d="M13.0185191,4.25291223 L12.9746137,4.25291223 C10.1097846,4.25291223 8.67188189,6.6128289 8.5182129,8.92335198 C8.39747298,10.6740809 8.73225185,12.8528876 14.0777375,18.4782704 C14.7127154,19.1080239 15.5654911,19.4695694 16.4596069,19.4880952 C17.3247917,19.4700909 18.1444718,19.0969678 18.7262246,18.4563177 C19.3189478,17.9074999 24.5052763,12.5894551 24.3570955,8.98921012 C24.2363556,6.42623084 22.123407,4.25291223 19.7525139,4.25291223 C18.5053576,4.22947431 17.3125171,4.76253118 16.4980242,5.70727948 C15.6177331,4.73767759 14.354699,4.20555668 13.04596,4.25291223 L13.0185191,4.25291223 Z"
                                    fill="#0090E3"
                                  />
                                  <path
                                    class="heart-full"
                                    d="M13.0185191,4.25291223 L12.9746137,4.25291223 C10.1097846,4.25291223 8.67188189,6.6128289 8.5182129,8.92335198 C8.39747298,10.6740809 8.73225185,12.8528876 14.0777375,18.4782704 C14.7127154,19.1080239 15.5654911,19.4695694 16.4596069,19.4880952 C17.3247917,19.4700909 18.1444718,19.0969678 18.7262246,18.4563177 C19.3189478,17.9074999 24.5052763,12.5894551 24.3570955,8.98921012 C24.2363556,6.42623084 22.123407,4.25291223 19.7525139,4.25291223 C18.5053576,4.22947431 17.3125171,4.76253118 16.4980242,5.70727948 C15.6177331,4.73767759 14.354699,4.20555668 13.04596,4.25291223 L13.0185191,4.25291223 Z"
                                    fill="red"
                                  />
                                  <path
                                    class="heart-lines"
                                    d="M26,4 L30.6852129,0.251829715"
                                    stroke="red"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                  />
                                  <path
                                    class="heart-lines"
                                    d="M2.314788,4 L7.00000086,0.251829715"
                                    stroke="red"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    transform="matrix(-1 0 0 1 10.314788 1)"
                                  />
                                  <path
                                    class="heart-lines"
                                    d="M27,12 L33,12"
                                    stroke="red"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                  />
                                  <path
                                    class="heart-lines"
                                    d="M0,12 L6,12"
                                    stroke="red"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    transform="matrix(-1 0 0 1 7 1)"
                                  />
                                  <path
                                    class="heart-lines"
                                    d="M24,19 L28.6852129,22.7481703"
                                    stroke="red"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                  />
                                  <path
                                    class="heart-lines"
                                    d="M4.314788,19 L9.00000086,22.7481703"
                                    stroke="red"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    transform="matrix(-1 0 0 1 14.314788 1)"
                                  />
                                </g>
                              </svg>
                            </div>
                          </div>
                          <div v-else-if="item.isWishlisted == 0">
                            <div
                              class="button-bidzzz"
                              @click="addorRemove(item)"
                            >
                              <svg
                                width="35px"
                                height="25px"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fill-rule="evenodd">
                                  <path
                                    class="heart-stroke"
                                    d="M13.0185191,4.25291223 L12.9746137,4.25291223 C10.1097846,4.25291223 8.67188189,6.6128289 8.5182129,8.92335198 C8.39747298,10.6740809 8.73225185,12.8528876 14.0777375,18.4782704 C14.7127154,19.1080239 15.5654911,19.4695694 16.4596069,19.4880952 C17.3247917,19.4700909 18.1444718,19.0969678 18.7262246,18.4563177 C19.3189478,17.9074999 24.5052763,12.5894551 24.3570955,8.98921012 C24.2363556,6.42623084 22.123407,4.25291223 19.7525139,4.25291223 C18.5053576,4.22947431 17.3125171,4.76253118 16.4980242,5.70727948 C15.6177331,4.73767759 14.354699,4.20555668 13.04596,4.25291223 L13.0185191,4.25291223 Z"
                                    fill="#0090E3"
                                  />
                                  <path
                                    class="heart-full"
                                    d="M13.0185191,4.25291223 L12.9746137,4.25291223 C10.1097846,4.25291223 8.67188189,6.6128289 8.5182129,8.92335198 C8.39747298,10.6740809 8.73225185,12.8528876 14.0777375,18.4782704 C14.7127154,19.1080239 15.5654911,19.4695694 16.4596069,19.4880952 C17.3247917,19.4700909 18.1444718,19.0969678 18.7262246,18.4563177 C19.3189478,17.9074999 24.5052763,12.5894551 24.3570955,8.98921012 C24.2363556,6.42623084 22.123407,4.25291223 19.7525139,4.25291223 C18.5053576,4.22947431 17.3125171,4.76253118 16.4980242,5.70727948 C15.6177331,4.73767759 14.354699,4.20555668 13.04596,4.25291223 L13.0185191,4.25291223 Z"
                                    fill="red"
                                  />
                                  <path
                                    class="heart-lines"
                                    d="M26,4 L30.6852129,0.251829715"
                                    stroke="red"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                  />
                                  <path
                                    class="heart-lines"
                                    d="M2.314788,4 L7.00000086,0.251829715"
                                    stroke="red"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    transform="matrix(-1 0 0 1 10.314788 1)"
                                  />
                                  <path
                                    class="heart-lines"
                                    d="M27,12 L33,12"
                                    stroke="red"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                  />
                                  <path
                                    class="heart-lines"
                                    d="M0,12 L6,12"
                                    stroke="red"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    transform="matrix(-1 0 0 1 7 1)"
                                  />
                                  <path
                                    class="heart-lines"
                                    d="M24,19 L28.6852129,22.7481703"
                                    stroke="red"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                  />
                                  <path
                                    class="heart-lines"
                                    d="M4.314788,19 L9.00000086,22.7481703"
                                    stroke="red"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    transform="matrix(-1 0 0 1 14.314788 1)"
                                  />
                                </g>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row hidinglast2on768-991">
                        <div
                          class="col-md-2 col-sm-2 bidlistwidth50p bidlistwidthfor768-991"
                        >
                          <div class="">
                            <div>
                              <i class="fas fa-map-marker-alt pdtListico"></i>
                              <div class="pdtListicon">
                                <!-- <span>Location</span> -->
                                <p class="fontWeightbidlist main-tile-location">
                                  {{ item.locName }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="col-md-2 col-sm-2 bidlistwidth50p bidlistwidthfor768-991"
                        >
                          <div class="d-flex align-items-center">
                            <img
                              src="../../public/assets/css/img/manual-transmission.png"
                              alt=""
                              style="width: 13px; margin-right: 5px"
                            />
                            <p class="fontWeightbidlist main-tile-location m-0">
                              {{ item.hp }}
                            </p>
                          </div>
                        </div>
                        <div
                          class="col-md-4 col-sm-4 bidlistwidth50p bidlistwidthfor768-991"
                        >
                          <div class="">
                            <div>
                              <i
                                class="far fa-calendar-alt pdtListico greenIcon"
                              ></i>
                              <div class="pdtListicon">
                                <!-- <span>End Date</span> -->
                                <p
                                  class="fontWeightbidlist greenIcon timesplit"
                                >
                                  {{ item.aucMatDate | formatDate }} IST
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          class="col-md-4 col-sm-4 bidlistwidth50p bidlistwidthfor768-991"
                        >
                          <div class="">
                            <div>
                              <i class="far fa-clock pdtListico greenIcon"></i>
                              <div class="pdtListicon">
                                <!-- <span>Time Left</span> -->
                                <p v-if="item.actualMaturityDate < datenow">
                                  AUCTION HAS ENDED
                                </p>
                                <p v-else class="fontWeightbidlist greenIcon">
                                  <Countdown
                                    :deadline="item.actualMaturityDate"
                                  ></Countdown>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="col-md-3 col-sm-3 bidlistwidth50p bidlistwidthfor768-991"
                        >
                          <div class="">
                            <div class="c-mar-top">
                              <div class="">
                                <!-- <span>Min. Increment</span> -->
                                <p class="fontWeightbidlist">
                                  Inc. {{ item.aucMinBid | currency }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="col-md-3 col-sm-3 bidlistwidth50p bidlistwidthfor768-991"
                        >
                          <div class="c-mar-top">
                            <div>
                              <i class="fas fa-tachometer-alt pdtListico"></i>
                              <div class="pdtListicon">
                                <!-- <span>Km Clocked </span> -->
                                <p class="fontWeightbidlist">
                                  {{ item.kmClocked }} KM
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="col-md-3 col-sm-3 bidlistwidth50p bidlistwidthfor768-991"
                        >
                          <div class="">
                            <div>
                              <div class="text-center c-mar-top">
                                <p class="bidzcarnum">
                                  {{ item.vehRegNo | truncate(5) }} ****
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="col-md-3 col-sm-3 bidlistwidth50p bidlistwidthfor768-991"
                        >
                          <div class="">
                            <div>
                              <div class="text-center c-mar-top">
                                <p class="numbidz">{{ item.bidzCount }} BIDS</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="text-center">
                        <div class="marginbidtop">
                          <span>CURRENT PRICE</span>
                          <div>
                            <h4
                              class="cu-bi-am"
                              v-if="item.latestBidAmount == null"
                            >
                              ₹ 0
                            </h4>
                            <h4 class="cu-bi-am" v-else>
                              <span
                                style="color: red"
                                v-if="
                                  item.latestBidAmount >
                                  item.customerLastBidAmount
                                "
                              >
                                {{ item.latestBidAmount | currency }} 
                                <span>
                                <i class="fa fa-caret-down" aria-hidden="true" style="font-size: 20px;">

                                </i>
                              </span>
                              </span>
                              <span style="color: green" v-else>
                                {{ item.latestBidAmount | currency }}
                              <span> <i class="fa fa-caret-up" aria-hidden="true" style="font-size: 20px;"></i>
                              </span>
                                </span
                              >
                            </h4>
                          </div>
                          <div class="marginbidtop">
                            <b-button
                              v-b-modal.modalbidz
                              class="bidBtn"
                              @click="sendInfo(item)"
                              >BID NOW</b-button
                            >
                          </div>
                        </div>
                        <div>
                          <div>
                            <div class="row">
                              <div class="col">
                                <div>
                                  <a href="">
                                    <div class="bidzNumberz">
                                      {{ item.bidzCount }}
                                    </div>
                                  </a>
                                </div>
                              </div>
                              <div class="col">
                                <div>
                                  <router-link to="Details">
                                    <div class="detailsButtonz">Details</div>
                                  </router-link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Card content ends here -->
                <!-- Show a pair of images after every 2nd card -->
                <div
                  v-if="
                    (index + 1) % 2 === 0 && index !== filteredItems.length - 1
                  "
                  class="between-cards-image"
                >
                  <div class="row">
                    <div
                      class="col-md-6"
                      v-for="(image, imgIndex) in getImagePair(index)"
                      :key="imgIndex"
                    >
                      <a
                        :href="image.Link"
                        target="_blank"
                        rel="noopener noreferrer"
                      > <img
                        :src="path + image.imageUrl"
                        alt="Between Card Image"
                      /></a>                     
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="filteredItems.length < 1">
                <notfound :checkdata="false"></notfound>
              </div>
              <div class="overflow-auto" v-if="filteredItems.length > 0">
                <div class="mt-3">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    align="center"
                    pills
                    first-number
                    last-number
                  ></b-pagination>
                </div>
              </div>
              <div>
                <b-modal
                  id="modalbidz"
                  ref="modal"
                  @hidden="resetModal"
                  hide-footer
                  v-if="currentUser"
                >
                  <form ref="form" v-if="issuccess != 'Success'">
                    <div
                      v-if="
                        selectedUser.latestBidAmount == null ||
                        selectedUser.latestBidAmount == ''
                      "
                    >
                      <p for="bidamount" class="bidamountzz">
                        Enter your bid amount
                      </p>

                      <div class="row bidsentry" style="flex-direction: row">
                        <div class="col-6">
                          <p
                            for="bidamount"
                            class="bidamountzz"
                            style="
                              margin-right: 1rem;
                              white-space: nowrap;
                              text-overflow: ellipsis;
                              margin-bottom: 0px;
                              margin-top: 6px;
                            "
                          >
                            {{ selectedUser.aucStartPrice | currency }} +
                          </p>
                        </div>
                        <div class="col-6">
                          <input
                            type="number"
                            class="form-control"
                            id="bidamount"
                            v-model="formattedBidAmount"
                            name="bidamont"
                            style="width: 100%"
                          />
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <p for="bidamount" class="bidamountzz">
                        Enter your bid amount
                      </p>

                      <div class="row bidsentry" style="flex-direction: row">
                        <div class="col-6">
                          <p
                            for="bidamount"
                            class="bidamountzz"
                            style="
                              margin-right: 1rem;
                              white-space: nowrap;
                              text-overflow: ellipsis;
                              margin-bottom: 0px;
                              margin-top: 6px;
                            "
                          >
                            {{ selectedUser.latestBidAmount | currency }} +
                          </p>
                        </div>
                        <div class="col-6">
                          <input
                            type="text"
                            class="form-control"
                            id="bidamount"
                            v-model="formattedBidAmount"
                            name="bidamont"
                            style="width: 100%"
                          />
                        </div>
                      </div>
                    </div>

                    <button
                      :disabled="isLoading"
                      type="button"
                      class="btn subBtnzz"
                      @click="bidentry(selectedUser)"
                      style="width: 100%; margin-top: 10px"
                    >
                      <span v-show="!isLoading"> SUBMIT</span>
                      <span v-show="isLoading"
                        >Submiting... <i class="fa fa-spinner fa-pulse"></i
                      ></span>
                    </button>
                    <div class="form-group" v-if="message == 'Success'">
                      <div class="alert alert-warning" role="alert">
                        {{ message }}
                      </div>
                    </div>
                    <div class="form-group" v-else-if="message">
                      <div class="alert alert-warning" role="alert">
                        {{ message }}
                      </div>
                    </div>
                  </form>
                  <form v-else-if="issuccess == 'Success'">
                    <successMessage
                      :aucName="selectedUser.aucName"
                      :bidAmount="selectedUser.aucMinmumBid"
                    >
                    </successMessage>
                  </form>
                </b-modal>

                <!-- <b-modal
            id="modalbidz"
            ref="modal"
            hide-footer
            v-else-if="!currentUser"
          >
            <form ref="form">
              <div>
                <p for="bidamount" class="bidamountzz">Login to BID</p>
              </div>


              <button
                type="submit"
                class="btn subBtnzz"
                @click="gotologin()"
                style="width: 100%; margin-top: 10px"
              >
                Go To Login
              </button>
            </form>
          </b-modal> -->
              </div>
            </div>
            <!-- onload -->
          </div>
          <!-- </div> -->
        </div>
        <!-- </div> -->
      </section>
    </div>
  </div>
</template>

<script>
import userService from "../services/user.service";
import Config from "../config";
import axios from "axios";
import userinformation from "../models/localstorage";
import moment from "moment";
// import $ from "jquery";

export default {
  name: "ProductCard",

  props: ["changed_url"],
  watch: {
    "$route.query.search"() {
      let url_key = this.$route.query.search;
      this.getAuctionList(url_key);
    },
  },
  data() {
    return {
      lists: [],
      perPage: 20,
      currentPage: 1,
      path: Config.BASE_URL_IMG,
      currentlist: "",
      message: "",
      modalShow: false,
      bid: "",
      customerId: "",
      st: "",
      selectedUser: "",
      selectedCategory: "All",
      isActiveBrand: false,
      isActiveModal: false,
      isActiveLocation: false,
      isActiveKms: false,
      isActiveYears: false,
      filtersAppied: [],
      isBitEntryModelActive: false,
      timeIntervel: Config.RefreshAuctionPage,
      timer: "",
      CountTimer: "",
      interval: null,
      dateNow: null,
      isLoading: false,
      issuccess: "",
      vehicleType: [],
      initialLoading: true,
      veBrand: [],
      veModel: [],
      veLocation: [],
      veKmclocked: [],
      veYearofmake: [],
      minimumBidAmount: null,
      maximumBidAmount: null,
      datenow: new Date(),
      pairImages: [],
      imagesList: [],
    };
  },

  filters: {
    truncate: function (data, num) {
      const reqdString = data.split("").slice(0, num).join("");
      return reqdString;
    },
    formatDate: function (value) {
      if (value) {
        return moment(String(value)).format("D-MMM-YYYY h:mm a ");
      }
    },
    currency: function (value) {
      if (typeof value !== "number") {
        return value;
      }
      let country = Config.countryCode;
      let crncy = Config.curency;
      let FractionDigits = Config.minFractionDigits;
      const formatter = new Intl.NumberFormat(country, {
        style: "currency",
        currency: crncy,
        minimumFractionDigits: FractionDigits,
      });
      return formatter.format(value);
    },
    //  dateISO(value){
    //    if(value){
    //        var d = new Date(value);
    //       //  d = d.toLocaleString("en-US", { timeZone:  Config.CurrNttimezone });
    //       // var date = new Date(d);
    //       return d.toISOString();
    //  }
    // },
  },
  computed: {
    formattedBidAmount: {
      get() {
        const bidAmount = this.selectedUser.aucMinmumBid;

        if (bidAmount === null || bidAmount === "" || isNaN(bidAmount)) {
          return "";
        } else {
          return bidAmount?.toLocaleString("en-IN");
        }
      },
      set(value) {
        const updatedBidAmount = this.selectedUser.aucMinmumBid;
        if (
          updatedBidAmount === null ||
          updatedBidAmount === "" ||
          isNaN(updatedBidAmount)
        ) {
          this.selectedUser.aucMinmumBid = parseFloat(value.replace(/,/g, ""));
        } else {
          this.selectedUser.aucMinmumBid = parseFloat(value.replace(/,/g, ""));
        }
      },
    },
    uniqueBrand() {
      return this.lists.reduce((seed, current) => {
        return Object.assign(seed, {
          [current.brandName]: current,
        });
      }, {});
    },
    uniqueModel() {
      return this.lists.reduce((seed, current) => {
        return Object.assign(seed, {
          [current.modelName]: current,
        });
      }, {});
    },
    uniqueLocation() {
      return this.lists.reduce((seed, current) => {
        return Object.assign(seed, {
          [current.locName]: current,
        });
      }, {});
    },
    uniqueKmClocked() {
      return this.lists.reduce((seed, current) => {
        return Object.assign(seed, {
          [current.kmClocked]: current,
        });
      }, {});
    },
    uniqueYearOfMake() {
      return this.lists.reduce((seed, current) => {
        return Object.assign(seed, {
          [current.manYear]: current,
        });
      }, {});
    },
    filteredItems() {
      return this.lists.filter((item) => {
        return (
          // (this.keyword.length === 0 || item.name.includes(this.keyword)) &&
          (this.veModel.length === 0 ||
            this.veModel.includes(item.modelName)) &&
          (this.veBrand.length === 0 ||
            this.veBrand.includes(item.brandName)) &&
          (this.veLocation.length === 0 ||
            this.veLocation.includes(item.locName)) &&
          (this.veKmclocked.length === 0 ||
            this.veKmclocked.includes(item.kmClocked)) &&
          (this.veYearofmake.length === 0 ||
            this.veYearofmake.includes(item.manYear))
        );
      });
      // .sort((a, b) => {
      //   console.log(this.sortBy);
      //   return a[this.sortBy]
      //     .toString()
      //     .localeCompare(b[this.sortBy].toString());
      // });
    },

    currentUser() {
      return this.$store.state.auth.user;
    },

    items() {
      return this.lists.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },

    totalRows() {
      return this.lists.length;
    },
  },
  created() {
    this.curntUser = userinformation;
    if (this.$store.state.auth.user) {
      this.st = userinformation[0].customerId;
    }
    this.timer = setInterval(this.refreshAuctionList, this.timeIntervel);

    this.vehicleType = this.$route.params;
  },
  mounted() {
    this.url_key = this.$route.query.search;

    this.refreshAuctionList();
    //  this.TimerCount();
    window.scrollTo(0, 0);
    // console.log(Config.CurrNttimezone);

    // datetime in "America/Chicago" timezone in the "en-US" locale
    this.dateNow = new Date().toLocaleString("en-US", {
      timeZone: Config.CurrNttimezone.toString(),
    });

    // "3/22/2021, 5:05:51 PM"
    // console.log(this.dateNow);

    this.fetchImages();
  },

  methods: {
    fetchImages() {
      userService
        .getBanners()
        .then((response) => {
          this.imagesList = response.data.Data.result6;

          // Group images in pairs and store them with their position
          for (let i = 0; i < this.imagesList.length; i += 2) {
            const pair = this.imagesList.slice(i, i + 2);
            const position = pair[0].position; // Assuming both images in a pair have the same position
            this.pairImages.push({ pair, position });
          }
        })
        .catch((e) => {
          console.log(e);
        });

      // Group images in pairs
      // for (let i = 0; i < this.imagesList.length; i += 2) {
      //   this.pairImages.push(this.imagesList.slice(i, i + 2));
      // }
    },
    getImagePair(index) {
      // Find the pair of images that should appear after the current index
      const currentPosition = index + 1;
      const imagePair = this.pairImages.find(
        (image) => image.position === currentPosition
      );
      return imagePair ? imagePair.pair : [];
    },
    TimerCount() {
      this.interval = setInterval(() => {
        this.timerWorker();
      }, 30000);

      // let aucExtDate =  Math.trunc(Date.parse(endTime.replace(/-/g, "/")) / 1000);
      // console.log(aucExtDate);
      //  this.CountTimer = setInterval(this.refreshAuctionList, 30000);
    },
    timerWorker() {
      // this.dateNow = moment(String(new Date())).format("DD-MM-YYYY hh:mm:ss");

      for (let index = 0; index < this.lists.length; index++) {
        const element = this.items[index];
        var acuDatev = moment(String(element.actualMaturityDate)).format(
          "DD-MM-YYYY hh:mm:ss"
        );

        var startTime = moment(acuDatev, "DD-MM-YYYY hh:mm:ss");
        var endTime = moment(this.dateNow, "DD-MM-YYYY hh:mm:ss");
        // if(acuDatev < this.dateNow){
        //     console.log(acuDatev);
        //     console.log(this.dateNow);
        // }
        //   if (Math.abs(secondsDiff) == 0) {
        //   console.log("Seconds:" + Math.abs(secondsDiff));

        // }
        // var hoursDiff = endTime.diff(startTime, "hours");
        // console.log("Hours:" + hoursDiff);

        // var minutesDiff = endTime.diff(startTime, "minutes");
        // console.log("Minutes:" + minutesDiff);

        var secondsDiff = endTime.diff(startTime, "seconds");

        if (Math.abs(secondsDiff) <= 30) {
          // console.log("Seconds:" + Math.abs(secondsDiff));
          this.refreshAuctionList();
        }
      }
    },
    refreshAuctionList() {
      let url_key = this.$route.query.search;
      this.fetchImages();
      //  console.log(this.isBitEntryModelActive);
      //  console.log(this.filtersAppied);
      if (
        typeof url_key != "undefined" &&
        this.isBitEntryModelActive == false &&
        this.filtersAppied.length <= 0
      ) {
        if (url_key == "" || url_key == null) {
          this.getCarList();
        } else {
          this.getAuctionList(url_key);
        }
      } else if (this.filtersAppied && this.isBitEntryModelActive == false) {
        // this.getCarList();

        this.getFilterList();
      } else if (this.isBitEntryModelActive == false) {
        this.getCarList();
      }
    },

    getFilterList() {
      if (this.filteredItems.length) {
        this.lists = this.filteredItems;
      } else {
        this.getCarList();
      }
    },
    ClearFilter() {
      this.initialLoading = true;
      this.veBrand = [];
      this.veModel = [];
      this.veLocation = [];
      this.veKmclocked = [];
      this.veYearofmake = [];
      //this.getCarList();
      location.reload();
      // this.items = [];
      // this.lists = [];
      // this.filtersAppied = [];
      (this.isActiveBrand = false),
        (this.isActiveModal = false),
        (this.isActiveLocation = false),
        (this.isActiveKms = false),
        (this.isActiveYears = false),
        (this.isBitEntryModelActive = false);
      // this.getCarList();
    },

    closeModal() {
      this.isBitEntryModelActive = false;
      var self = this;

      setTimeout(function () {
        self.hideModal();
      }, 2000);
    },
    hideModal() {
      this.issuccess = "";
      this.$refs["modal"].hide();
    },

    resetModal() {
      this.message = "";
      this.bid = "";
      this.message = null;
      this.bid = null;
      this.isBitEntryModelActive = false;
      this.issuccess = "";
    },

    getCarList() {
      if (this.st !== "undefind") {
        this.customerId = this.st;
      } else {
        this.customerId = "";
      }

      axios({
        method: "Get",
        url: Config.BASE_URL + "?sp=getAuctionsList&custId=" + this.customerId,
      })
        .then((resp) => {
          let vehList = resp.data.Data;
          //   console.log(vehList);
          var newArray = "";

          if (JSON.stringify(this.vehicleType) !== "{}") {
            if (this.vehicleType.vehTypeName) {
              let vtypename = this.vehicleType.vehTypeName;

              newArray = vehList.filter(function (el) {
                return el.vehTypeName == vtypename;
              });

              this.lists = newArray;
            }

            if (this.vehicleType.brandName) {
              let brandName = this.vehicleType.brandName;
              newArray = vehList.filter(function (el) {
                return el.brandName == brandName;
              });

              this.lists = newArray;
            }
          } else {
            this.lists = vehList;
          }

          if (this.lists.length > 0) {
            this.initialLoading = false;
            this.TimerCount();
          } else {
            this.initialLoading = false;
          }
        })
        .catch((err) => {
          console.log(err.response);
          this.initialLoading = false;
        });
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
    getAuctionList(url_key) {
      if (url_key) {
        this.searchkey = url_key;
        if (this.st) {
          this.customerId = this.st;
          // console.log(this.customerId + "get auction customer id");
        } else {
          this.customerId = "";
        }
      }
      userService
        .getAuctionList(this.customerId, this.searchkey)
        .then((resp) => {
          this.lists = resp.data.Data;
          if (this.lists.length > 0) {
            this.initialLoading = false;
            this.TimerCount();
          } else {
            this.initialLoading = false;
          }
        })
        .catch((err) => {
          console.log(err.response);
          this.initialLoading = false;
        });
    },
    addorRemove(elem) {
      if (this.$store.state.auth.user) {
        this.addWhishlist(elem);
      } else {
        this.removeguestid();
        this.$router.push("/MyAccount").catch(() => {
          "carlist";
        });
      }
    },
    removeguestid() {
      localStorage.setItem(
        "guestid",
        "_" + Math.random().toString(36).substr(2, 9)
      );
    },
    addWhishlist(elem) {
      userService.addToWhishlist(this.st, elem).then((response) => {
        if (response.data.Message == "Success") {
          this.makeToast("success", "Added to whishlist");
        }

        this.getCarList();
      });
    },
    bidentry(item) {
      this.isLoading = true;

      if (
        this.st &&
        this.selectedUser.aucMinmumBid !== "" &&
        this.selectedUser.aucMinmumBid !== null
      ) {
        let cusid = this.st;
        let bidamount = parseInt(this.selectedUser.aucMinmumBid);

        if (
          isNaN(bidamount) ||
          bidamount <= 0 ||
          bidamount < this.minimumBidAmount
        ) {
          this.message =
            "Bid amount should be greater than or equal to minimum bid amount" +
            " - " +
            this.minimumBidAmount;
          this.isLoading = false;
        } else if (bidamount > this.maximumBidAmount) {
          this.message =
            "Bid amount should be less than or equal to maximum bid amount" +
            " - " +
            this.maximumBidAmount;
          this.isLoading = false;
        } else {
          userService
            .addbid(item, cusid, bidamount)
            .then((response) => {
              this.message = response.data.Message;

              this.isLoading = false;
              this.closeModal();
              this.issuccess = this.message;
              setTimeout(() => {
                location.reload();
              }, 2000);
              // this.getCarList();
            })
            .catch((error) => {
              this.isLoading = false;
              this.message =
                (error.response && error.response.data.Message) ||
                error.message ||
                error.toString();

              if (this.message == "Purchase a package to continue ") {
                setTimeout(() => {
                  this.gotoPackagePage();
                }, 3000);
              }
              if(this.message =='Someone else might have placed a bid, Please refresh the page'){
                setTimeout(() => {
                  location.reload();
                }, 3000);
              }
            });
        }
      } else {
        this.isLoading = false;
        this.message = "Field is required";
      }
    },
    gotoPackagePage() {
      // Set the returnUrl value in session storage
      sessionStorage.setItem("returnUrl", "/Auction");
      window.location = "/package";
      // this.$router.push('/package');
    },
    gotologin() {
      localStorage.setItem(
        "guestid",
        "_" + Math.random().toString(36).substr(2, 9)
      );
      this.$router.push("/").catch(() => {
        "login";
      });
    },
    rmvfromWhishlist(item) {
      userService.rmvfromWhishlist(item, this.st).then((response) => {
        if (response.data.Message == "Success") {
          // this.message = " Removed from whishlist";
          this.makeToast("success", "Removed from wishlist");
          this.message = "";
        }

        this.getCarList();
      });
    },
    sendInfo(item) {
      if (!this.currentUser) {
        this.makeToast("warning", "! Please login to continue");

        setTimeout(() => {
          this.gotologin();
        }, 1000);
      } else {
        //  this.selectedUser = item;

        let selectedItems = {
          aucStartPrice: item.aucStartPrice,
          aucMinmumBid: item.aucMinBid,
          latestBidAmount: item.latestBidAmount,
          aucId: item.aucId,
        };
        this.minimumBidAmount = item.aucMinBid;
        this.maximumBidAmount = item.aucMaxBid;
        this.selectedUser = selectedItems;
        this.isBitEntryModelActive = true;
      }
    },
    makeToast(variant, body_content) {
      let tittleMessage = variant;
      if (variant == "danger") {
        tittleMessage = "Error";
      }
      if (variant == "success") {
        tittleMessage = "Success";
      }
      if (variant == "warning") {
        tittleMessage = "Warning";
      }

      this.$bvToast.toast(body_content, {
        title: `${tittleMessage || "default"}`,
        variant: variant,
        autoHideDelay: 5000,
        //solid: true,
        toaster: "b-toaster-bottom-right",
        appendToast: true,
      });
    },
  },

  beforeDestroy() {
    this.cancelAutoUpdate();
    clearInterval(this.interval);
  },
};
</script>

<style scoped>
.loading-overlay {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(100, 100, 100, 0.5);
}

i.fa.fa-cog {
  font-size: 3em;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.subBtnzz {
  background-image: linear-gradient(to bottom right, #1d1b2b, #004a6d);
  color: white;
  font-weight: 600;
}

.bidamountzz {
  font-size: 20px;
  font-weight: 600;
}

.heart-stroke {
  fill: none;
  stroke: #666;
  stroke-width: 2px;
  opacity: 1;
  transform-origin: center center;
}

.button-bidzzz.active .heart-stroke {
  opacity: 0;
}

.heart-full {
  opacity: 0;
  transform-origin: 50% 50%;
}

.button-bidzzz.active .heart-full {
  opacity: 1;
}

.heart-lines {
  stroke-width: 2px;
  display: none;
}

.button-bidzzz:not(.active):hover .heart-stroke {
  -webkit-animation: pulse 1s ease-out infinite;
  animation: pulse 1s ease-out infinite;
}

.button-bidzzz.animate .heart-full {
  -webkit-animation: heart 0.35s;
  animation: heart 0.35s;
}

.button-bidzzz.animate .heart-lines {
  -webkit-animation: lines 0.2s ease-out forwards;
  animation: lines 0.2s ease-out forwards;
  display: block;
}

@-webkit-keyframes lines {
  0% {
    stroke-dasharray: 6;
    stroke-dashoffset: 16;
  }

  100% {
    stroke-dasharray: 13;
    stroke-dashoffset: 18;
  }
}

@keyframes lines {
  0% {
    stroke-dasharray: 6;
    stroke-dashoffset: 16;
  }

  100% {
    stroke-dasharray: 13;
    stroke-dashoffset: 18;
  }
}

@-webkit-keyframes heart {
  0% {
    transform: scale(1);
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  10% {
    transform: scale(1.2);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  35% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  75% {
    transform: scale(1.1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  100% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@keyframes heart {
  0% {
    transform: scale(1);
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  10% {
    transform: scale(1.2);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  35% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  75% {
    transform: scale(1.1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  100% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@-webkit-keyframes pulse {
  0% {
    opacity: 1;
    transform-origin: center center;
    transform: scale(1);
  }

  50% {
    opacity: 0.6;
    transform: scale(1.15);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
    transform-origin: center center;
    transform: scale(1);
  }

  50% {
    opacity: 0.6;
    transform: scale(1.15);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

#refresh {
  position: fixed;
  bottom: 120px;
  right: 20px;
  background-color: #ffffff;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1);
  padding: 17px 0 0 17px;
}

.icon {
  fill: #d3d3d3;
  transform: rotate(0deg);
  transition: fill 0.25s ease, transform 0.25s ease;
}

.icon:hover {
  fill: #1059ff;
  transform: rotate(180deg);
}

@media only screen and (max-width: 660px) {
  .vehicle-filter-close {
    display: none;
  }
}
</style>
