<template>
  <div>
    <div>
      <main class="page-content">
        <div class="account-page-area">
          <div class="container containerDash">
            <div class="row">
              <div class="col-lg-3 account_border-right">
                <sidebar-dashboard-customer></sidebar-dashboard-customer>
              </div>
              <div class="col-lg-9">
                <h3>My Bids</h3>
                <div v-if="!items.length">
                  <notfound :checkdata="initialLoading"></notfound>
                </div>

                <section style="padding: 10px 0px">
                  <div class="bidz-container-list" v-if="items.length">
                    <div
                      class="pdtListingWrap"
                      v-for="(item, index) in items"
                      :key="index"
                    >
                      <div class="row">
                        <!-- <div class="col-md-2">
                <div class="bidlistImagewrap">
                  <img
                    :src="path+ '/'+item.vehImage1" 
                    class="img-fluid img-responsive border5px"
                  />
                </div>
              </div> -->
                        <div class="col-md-8 bidborderright">
                          <div class="">
                            <div class="bidlistDetailswrapwidth85">
                              <div class="bidlistDetailswrap cust">
                                <router-link
                                  class="line-clamp cModalname"
                                  style="
                                    font-size: 25px;
                                    color: black;
                                    font-weight: 500;
                                    margin: 0;
                                  "
                                  :to="{
                                    name: 'bidhistory',
                                    params: { url_key: item.aucName },
                                  }"
                                >
                                  {{ item.brandName + " - " + item.modelName }}
                                </router-link>
                              </div>
                            </div>
                          </div>
                          <div
                            class="row hidinglast2on768-991"
                            style="row-gap: 10px"
                          >
                            <div
                              class="col-md-4 col-sm-4 bidlistwidth50p bidlistwidthfor768-991"
                            >
                              <div class="">
                                <div>
                                  <i
                                    class="fas fa-tachometer-alt pdtListico"
                                  ></i>
                                  <div class="pdtListicon">
                                    <!-- <span>Km Clocked</span> -->
                                    <p class="fontWeightbidlist">
                                      {{ item.kmClocked }} KM
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-md-4 col-sm-4 bidlistwidth50p bidlistwidthfor768-991"
                            >
                              <div class="">
                                <div>
                                  <i
                                    class="fas fa-map-marker-alt pdtListico"
                                  ></i>
                                  <div class="pdtListicon">
                                    <!-- <span>Location</span> -->
                                    <p
                                      class="fontWeightbidlist main-tile-location"
                                    >
                                      {{ item.locName }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              class="col-md-4 col-sm-4 bidlistwidth50p bidlistwidthfor768-991"
                            >
                              <div class="">
                                <div>
                                  <div class="text-center">
                                    <!-- <span>Min. Increment</span> -->
                                    <p class="fontWeightbidlist">
                                      Inc. {{ item.aucMinBid | currency }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              class="col-md-4 col-sm-4 bidlistwidth50p bidlistwidthfor768-991"
                            >
                              <div class="">
                                <div>
                                  <i
                                    class="far fa-clock pdtListico greenIcon"
                                  ></i>
                                  <div class="pdtListicon">
                                    <!-- <span>Time Left</span> -->

                                    <p v-if="item.actualMaturityDate < datenow">
                                      AUCTION HAS ENDED
                                    </p>
                                    <p
                                      v-else
                                      class="fontWeightbidlist greenIcon"
                                    >
                                      <Countdown
                                        :deadline="item.actualMaturityDate"
                                      ></Countdown>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              class="col-md-4 col-sm-4 bidlistwidth50p bidlistwidthfor768-991"
                            >
                              <div class="">
                                <div>
                                  <i
                                    class="far fa-calendar-alt pdtListico greenIcon"
                                  ></i>
                                  <div class="pdtListicon">
                                    <!-- <span>End Date</span> -->
                                    <p class="fontWeightbidlist greenIcon">
                                      {{ item.aucMatDate | formatDate }} IST
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              class="col-md-4 col-sm-4 bidlistwidth50p bidlistwidthfor768-991"
                            >
                              <div class="">
                                <div>
                                  <div class="text-center">
                                    <p class="bidzcarnum">
                                      {{ item.vehRegNo | truncate(5) }} ****
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="text-center">
                            <div class="">
                              <span class="spanzz">CURRENT BID AMOUNT</span>
                              <h4 v-if="item.latestBidAmount == null">₹ 0</h4>
                              <h4 v-else class="curr-bid-am">
                                {{ item.latestBidAmount | currency }}
                              </h4>
                              <div class="row">
                                <div class="col-md-6">
                                  <div
                                    class="marginbidtop"
                                    v-if="item.actualMaturityDate < datenow"
                                  >
                                    <div class="marginbidtop">
                                      <b-button
                                        disabled
                                        v-b-modal.modalbidz
                                        class="bidBtn bid-now-mbds-pos"
                                        @click="sendInfo(item)"
                                        >BID EXPIRED
                                      </b-button>
                                    </div>
                                  </div>
                                  <div class="marginbidtop" v-else>
                                    <div class="marginbidtop">
                                      <b-button
                                        v-b-modal.modalbidz
                                        class="bidBtn"
                                        @click="sendInfo(item)"
                                        >BID NOW</b-button
                                      >
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="marginbidtop">
                                    <span class="spanzz">MY LATEST BID</span>

                                    <p
                                      v-if="
                                        item.latestBidAmount <=
                                        item.customerLastBidAmount
                                      "
                                      class="bidColorbtn"
                                    >
                                      {{
                                        item.customerLastBidAmount | currency
                                      }}
                                       <i class="fa fa-caret-up" aria-hidden="true" style="font-size: 20px;"></i>
                                    </p>
                                    <p
                                      v-else-if="
                                        item.latestBidAmount >
                                        item.customerLastBidAmount
                                      "
                                      class="bidColorbtnRed"
                                    >
                                      {{
                                        item.customerLastBidAmount | currency
                                      }}
                                     <i class="fa fa-caret-down" aria-hidden="true" style="font-size: 20px;"></i>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <!-- <div class="marginbidtop">
                                <span class="spanzz">NUMBER OF BIDS</span>
                                <a class="bidColorbtn"  @click.prevent="openThread"
                                  >{{ item.bidzCount }} BIDS</a
                                >
                              </div> -->
                            </div>
                            <div></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="overflow-auto">
                      <div class="mt-3">
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="totalRows"
                          :per-page="perPage"
                          align="center"
                          pills
                          first-number
                          last-number
                        ></b-pagination>
                      </div>
                    </div>
                    <div>
                      <b-modal
                        id="modalbidz"
                        @show="resetModal"
                        @hidden="resetModal"
                        ref="modal"
                        hide-footer
                        v-if="currentUser"
                      >
                        <form ref="form" v-if="issuccess != 'Success'">
                          <div
                            v-if="
                              selectedUser.latestBidAmount <
                              selectedUser.aucStartPrice
                            "
                          >
                            <p for="bidamount" class="bidamountzz">
                              Enter your bid amount
                            </p>

                            <div
                              class="bidsentry"
                              style="
                                display: inline-flex;
                                flex-direction: row;
                                align-items: baseline;
                              "
                            >
                              <p
                                for="bidamount"
                                class="bidamountzz"
                                style="margin-right: 1rem"
                              >
                                {{ selectedUser.aucStartPrice | currency }} +
                              </p>
                              <input
                                type="text"
                                class="form-control"
                                id="bidamount"
                                v-model="formattedBidAmount"
                                name="bidamont"
                                style="width: 55%"
                              />
                            </div>
                          </div>
                          <div v-else>
                            <p for="bidamount" class="bidamountzz">
                              Enter your bid amount
                            </p>

                            <div
                              class="bidsentry"
                              style="
                                display: inline-flex;
                                flex-direction: row;
                                align-items: baseline;
                              "
                            >
                              <p
                                for="bidamount"
                                class="bidamountzz"
                                style="margin-right: 1rem"
                              >
                                {{ selectedUser.latestBidAmount | currency }} +
                              </p>
                              <input
                                type="text"
                                class="form-control"
                                id="bidamount"
                                v-model="formattedBidAmount"
                                name="bidamont"
                                style="width: 55%"
                              />
                            </div>
                          </div>

                          <button
                            type="button"
                            class="btn subBtnzz"
                            @click="bidentry(selectedUser)"
                            style="width: 100%; margin-top: 10px"
                          >
                            <span v-show="!isLoading"> SUBMIT</span>
                            <span v-show="isLoading"
                              >Submiting...
                              <i class="fa fa-spinner fa-pulse"></i
                            ></span>
                          </button>
                          <div class="form-group" v-if="message == 'Success'">
                            <div class="alert alert-warning" role="alert">
                              {{ message }}
                            </div>
                          </div>
                          <div class="form-group" v-else-if="message">
                            <div class="alert alert-warning" role="alert">
                              {{ message }}
                            </div>
                          </div>
                        </form>
                        <form v-else-if="issuccess == 'Success'">
                          <successMessage
                            :aucName="selectedUser.aucName"
                            :bidAmount="selectedUser.aucMinBid"
                          ></successMessage>
                        </form>
                      </b-modal>

                      <b-modal
                        id="modalbidz"
                        ref="modal"
                        hide-footer
                        v-else-if="!currentUser"
                      >
                        <form ref="form">
                          <div>
                            <p for="bidamount" class="bidamountzz">
                              Login to BID
                            </p>
                          </div>
                          <button
                            type="submit"
                            class="btn subBtnzz"
                            @click="gotologin()"
                            style="width: 100%; margin-top: 10px"
                          >
                            Go To Login
                          </button>
                        </form>
                      </b-modal>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import userService from "../services/user.service";
import userinformation from "../models/localstorage";
import Config from "../config";
import moment from "moment";
export default {
  name: "ProductCard",

  data() {
    return {
      lists: [],
      perPage: 10,
      currentPage: 1,
      path: Config.BASE_URL_IMG,
      message: "",
      modalShow: false,
      bid: "",
      customerId: "",
      st: "",
      selectedUser: [],
      // fadev:"opacity=0.5;",
      datenow: moment(new Date())
        .add(5, "hours")
        .add(30, "minutes")
        .toISOString(),
      isLoading: false,
      issuccess: "",
      initialLoading: true,
      minimumBidAmount: null,
      maximumBidAmount: null,
    };
  },

  //   watch: {
  //   currentPage() {
  //     this.currentPageCountdown = this.calculateCountdown();
  //   },
  // },
  created() {
    if (this.loggedIn) {
      if (
        !this.$store.state.auth.user[0].customerId == "" ||
        !this.$store.state.auth.user[0].customerId == " "
      ) {
        this.$router.push("/MyBidz").catch(() => {
          "home";
        });
      } else {
        this.$router.push("/").catch(() => {
          "home";
        });
      }
    }

    if (!this.currentUser) {
      this.$router.push("/").catch(() => {
        "mybidz";
      });
    } else {
      this.st = userinformation[0];
      this.getCarList();
    }
  },
  updated() {
    window.scrollTo(0, 0);
  },

  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    items() {
      return this.lists.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
    totalRows() {
      return this.lists.length;
    },

    formattedBidAmount: {
      get() {
        const bidAmount = this.selectedUser.aucMinBid;

        if (bidAmount === null || bidAmount === "" || isNaN(bidAmount)) {
          return 0;
        } else {
          return bidAmount?.toLocaleString("en-IN");
        }
      },
      set(value) {
        const updatedBidAmount = this.selectedUser.aucMinBid;
        if (
          updatedBidAmount === null ||
          updatedBidAmount === "" ||
          isNaN(updatedBidAmount)
        ) {
          this.selectedUser.aucMinBid = parseFloat(value.replace(/,/g, ""));
        } else {
          this.selectedUser.aucMinBid = parseFloat(value.replace(/,/g, ""));
        }
      },
    },
  },

  mounted() {},
  filters: {
    dateISO(value) {
      if (value) {
        var d = new Date(value);
        //  d = d.toLocaleString("en-US", { timeZone:  Config.CurrNttimezone });
        // var date = new Date(d);
        return d.toISOString();
      }
    },
    truncate: function (data, num) {
      const reqdString = data.split("").slice(0, num).join("");
      return reqdString;
    },
    formatDate: function (value) {
      if (value) {
        return moment(String(value)).format("D-MMM-YYYY h:mm a");
      }
    },
    currency: function (value) {
      if (typeof value !== "number") {
        return value;
      }
      let country = Config.countryCode;
      let crncy = Config.curency;
      let FractionDigits = Config.minFractionDigits;
      const formatter = new Intl.NumberFormat(country, {
        style: "currency",
        currency: crncy,
        minimumFractionDigits: FractionDigits,
      });
      return formatter.format(value);
    },
  },
  methods: {
    closeModal() {
      var self = this;

      setTimeout(function () {
        self.hideModal();
      }, 2000);
    },
    hideModal() {
      this.issuccess = "";
      this.$refs["modal"].hide();
      location.reload();
    },

    resetModal() {
      this.message = "";
      this.bid = "";
      this.message = null;
      this.bid = null;
      this.issuccess = "";
      this.selectedUser.aucMinBid = this.minimumBidAmount;
    },
    sendInfo(item) {
      this.selectedUser = item;
      this.minimumBidAmount = item.aucMinBid;
      this.maximumBidAmount = item.aucMaxBid;
    },
    getCarList() {
      let customerId = this.st.customerId;
      userService
        .getbid(customerId)
        .then((resp) => {
          this.lists = resp.data.Data;
          this.initialLoading = false;
        })
        .catch((err) => {
          console.log(err.response);
          this.initialLoading = false;
        });
    },
    bidentry(selectedUser) {
      this.isLoading = true;
      // console.log(this.st.customerId);
      if (
        this.st &&
        this.selectedUser.aucMinBid !== "" &&
        this.selectedUser.aucMinBid !== null
      ) {
        let cusid = this.st.customerId;
        let bidamount = parseInt(this.selectedUser.aucMinBid);
        if (
          isNaN(bidamount) ||
          bidamount <= 0 ||
          bidamount < this.minimumBidAmount
        ) {
          this.message =
            "Bid amount should be greater than or equal to minimum bid amount" +
            " - " +
            this.minimumBidAmount;
          this.isLoading = false;
        } else if (bidamount > this.maximumBidAmount) {
          this.message =
            "Bid amount should be less than or equal to maximum bid amount" +
            " - " +
            this.maximumBidAmount;
          this.isLoading = false;
        } else {
          userService
            .addbid(selectedUser, cusid, bidamount)
            .then((response) => {
              // console.log(response.data.Message);
              this.message = response.data.Message;
              this.isLoading = false;
              this.closeModal();
              this.issuccess = this.message;
              this.getCarList();
            })
            .catch((error) => {
              this.isLoading = false;
              this.message =
                (error.response && error.response.data.Message) ||
                error.message ||
                error.toString();
              if (this.message == "Purchase a package to continue ") {
                setTimeout(() => {
                  window.location = "/package";
                }, 3000);
              }
              if(this.message =='Someone else might have placed a bid, Please refresh the page'){
                setTimeout(() => {
                  location.reload();
                }, 3000);
              }
            });
        }
      } else {
        this.isLoading = false;
        this.message = "Field is required";
      }
    },
  },
};
</script>

<style scoped>
.loading-overlay {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(100, 100, 100, 0.5);
}

i.fa.fa-cog {
  font-size: 3em;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.bidColorbtn {
  padding: 3px 20px;
}
.bidBtn {
  padding: 3px 6px !important;
}
.marginbidtop {
  margin-top: 5px;
}
.spanzz {
  font-size: 12px;
}

.subBtnzz {
  background-image: linear-gradient(to bottom right, #1d1b2b, #004a6d);
  color: white;
  font-weight: 600;
}
.bidamountzz {
  font-size: 20px;
  font-weight: 600;
}
.heart-stroke {
  fill: none;
  stroke: #ddd;
  stroke-width: 2px;
  opacity: 1;
  transform-origin: center center;
}
.button-bidzzz.active .heart-stroke {
  opacity: 0;
}
.heart-full {
  opacity: 0;
  transform-origin: 50% 50%;
}
.button-bidzzz.active .heart-full {
  opacity: 1;
}
.heart-lines {
  stroke-width: 2px;
  display: none;
}

.button-bidzzz:not(.active):hover .heart-stroke {
  -webkit-animation: pulse 1s ease-out infinite;
  animation: pulse 1s ease-out infinite;
}

.button-bidzzz.animate .heart-full {
  -webkit-animation: heart 0.35s;
  animation: heart 0.35s;
}
.button-bidzzz.animate .heart-lines {
  -webkit-animation: lines 0.2s ease-out forwards;
  animation: lines 0.2s ease-out forwards;
  display: block;
}

@-webkit-keyframes lines {
  0% {
    stroke-dasharray: 6;
    stroke-dashoffset: 16;
  }
  100% {
    stroke-dasharray: 13;
    stroke-dashoffset: 18;
  }
}

@keyframes lines {
  0% {
    stroke-dasharray: 6;
    stroke-dashoffset: 16;
  }
  100% {
    stroke-dasharray: 13;
    stroke-dashoffset: 18;
  }
}
@-webkit-keyframes heart {
  0% {
    transform: scale(1);
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(1.2);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  35% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  75% {
    transform: scale(1.1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heart {
  0% {
    transform: scale(1);
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(1.2);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  35% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  75% {
    transform: scale(1.1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@-webkit-keyframes pulse {
  0% {
    opacity: 1;
    transform-origin: center center;
    transform: scale(1);
  }
  50% {
    opacity: 0.6;
    transform: scale(1.15);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes pulse {
  0% {
    opacity: 1;
    transform-origin: center center;
    transform: scale(1);
  }
  50% {
    opacity: 0.6;
    transform: scale(1.15);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
