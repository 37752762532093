<template>
  <div>
    <section>
      <div>
        <div v-bind:class="computedClass">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <nav class="navbar navbar-expand-md navbar-light">
                  <a class="navbar-brand" href="/">
                    <!-- <img src="/assets/images/menu/logo/2.png" alt="" class="onlyShowMobile"/> -->
                    <div v-if="usermode.includes('sellerId') == true">
                      <img
                        src="/assets/images/menu/logo/main-logo.png"
                        alt=""
                        class="onlyShowDesktop"
                      />
                    </div>
                    <div v-else>
                      <img
                        src="/assets/images/menu/logo/main-logo.png"
                        alt=""
                        class="onlyShowDesktop"
                      />
                    </div>
                  </a>
                  <button
                    class="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span class="navbar-toggler-icon"></span>
                  </button>
                  <div
                    class="collapse navbar-collapse"
                    id="navbarSupportedContent"
                  >
                    <ul class="navbar-nav ml-auto py-4 py-md-0">
                      <!-- <li class="nav-item pl-4 pl-md-0 ml-0 ml-md-4 active">
									<a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Home</a>
									<div class="dropdown-menu">
										<a class="dropdown-item" href="#">Action</a>
										<a class="dropdown-item" href="#">Another action</a>
										<a class="dropdown-item" href="#">Something else here</a>
										<a class="dropdown-item" href="#">Another action</a>
									</div>
								</li> -->
                      <li class="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
                        <a
                          class="nav-link"
                          href="/"
                          v-if="!usermode.includes('sellerId')"
                          >Home</a
                        >
                      </li>

                      <li v-if="usermode.includes('sellerId')"></li>
                      <li class="nav-item pl-4 pl-md-0 ml-0 ml-md-4" v-else>
                        <a class="nav-link" href="/Auction">Auctions </a>
                      </li>
                      <li
                        class="nav-item pl-4 pl-md-0 ml-0 ml-md-4"
                        v-if="!currentUser"
                      >
                        <a class="nav-link" href="/Register">Register</a>
                      </li>
                      <li
                        class="nav-item pl-4 pl-md-0 ml-0 ml-md-4"
                        v-if="!currentUser"
                      >
                        <a class="nav-link" @click="removeguestid" href="/"
                          >Login</a
                        >
                      </li>

                      <li class="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
                        <a class="nav-link" href="/About">About</a>
                      </li>

                      <li
                        class="nav-item pl-4 pl-md-0 ml-0 ml-md-4"
                        v-if="usermode.includes('customerId')"
                      >
                        <a class="nav-link" @click="gotoPackagePage">
                          Packages</a
                        >
                      </li>
                      <li
                        class="nav-item pl-4 pl-md-0 ml-0 ml-md-4"
                        v-if="usermode.includes('customerId')"
                      >
                        <a class="nav-link" href="/MyAccount">
                          Welcome {{ currentuserinfo.custName }}</a
                        >
                      </li>
                      <li
                        class="nav-item pl-4 pl-md-0 ml-0 ml-md-4"
                        v-if="usermode.includes('sellerId')"
                      >
                        <a class="nav-link" href="/vendor">
                          Welcome {{ currentuserinfo.sellerName }}</a
                        >
                      </li>
                      <li
                        class="nav-item pl-4 pl-md-0 ml-0 ml-md-4"
                        v-if="currentUser"
                      >
                        <a
                          class="nav-link"
                          id="account-details-tab"
                          href
                          @click.prevent="logOut"
                          role="tab"
                          ><i class="fas fa-lock"></i> Logout</a
                        >
                      </li>

                      <li class="">
                        <!-- nav button -->
                        <div
                          class="search-btn-style"
                          v-if="usermode.includes('sellerId') != true"
                        >
                          <div v-b-toggle.collapse-1>
                            <div>
                              <i class="fas fa-search"></i>
                            </div>
                          </div>
                        </div>
                        <!-- nav button -->
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>

          <!-- card -->
          <div>
            <b-collapse id="collapse-1" class="mt-2">
              <div>
                <div class="wrap-headsearch">
                  <div class="search-tog">
                    <input
                      type="text"
                      class="searchTerm"
                      placeholder="What are you looking for?"
                      v-model="search"
                      @change="searchChange(search)"
                    />
                    <!-- <button type="submit" class="searchButton">
                    <i class="fa fa-search"></i>
                  </button> -->
                    <router-link
                      v-b-toggle.collapse-1
                      style="
                        background-color: #0094da;
                        color: white;
                        border-radius: 0px 5px 5px 0px;
                      "
                      v-if="usermode.includes('customerId') == true"
                      :to="{ path: '/AuctionList', query: { search: search } }"
                      ><div class="header-search_btn">
                        <i
                          class="ion-ios-search-strong"
                          style="
                            font-size: 30px;
                            margin-left: 12px;
                            margin-right: 12px;
                          "
                          ><span></span
                        ></i></div
                    ></router-link>

                    <router-link
                      v-b-toggle.collapse-1
                      style="background-color: #0094da; color: white"
                      v-else
                      :to="{ path: '/AuctionList', query: { search: search } }"
                      ><div class="header-search_btn">
                        <i
                          class="ion-ios-search-strong"
                          style="
                            font-size: 30px;
                            margin-left: 12px;
                            margin-right: 12px;
                          "
                          ><span></span
                        ></i></div
                    ></router-link>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
          <!-- card -->
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import sidebar from "./sidebar.vue";
import userService from "../services/user.service";
import userinformation from "../models/localstorage";
export default {
  data() {
    return {
      search: "",
      usermode: "",
      currentuserinfo: "",
    };
  },
  // components: { sidebar },
  props: {},
  watch: {
    changed_url(search) {
      this.search = search;
    },
  },

  created() {
    this.curntUser = userinformation;
  },

  mounted() {
    this.curntUser = userinformation;
    if (this.$store.state.auth.user) {
      this.usermode = JSON.stringify(this.$store.state.auth.user[0]);
      if (this.usermode.includes("customerId")) {
        this.getuserbyId();
      } else if (this.usermode.includes("sellerId")) {
        this.getsellerbyId();

        //const navbarColors = document.querySelectorAll(".nav-link");
        // if (navbarColors) {
        //   navbarColors.forEach((navbarColor) => {
        //     navbarColor.style.setProperty("color", "#c7bdbd", "important");
        //   });
        // }

        // const appContentsec = document.querySelector(".appContentsec");
        // if (appContentsec) {
        //   appContentsec.classList.add("white-text");
        // }

        // const element = document.querySelector(".downloadApp");
        // if (element) {
        //   element.classList.remove("downloadApp");
        //   element.classList.add("downloadAppSeller");
        // }
      }
    }
  },
  computed: {
    currentUser() {
      //  console.log(this.$store.state.auth.user + "token");
      return this.$store.state.auth.user;
    },
    computedClass() {
      let className = "navigation-wrap  bg-light start-header start-style";
      if (this.$store.state.auth.user) {
        if (
          this.$store.state.auth.user[0].sellerId != null ||
          (this.$store.state.auth.user[0].sellerId != "" &&
            this.$store.state.auth.user[0].customerId == null)
        ) {
          className =
            "navigation-wrap vendorBackground  start-header start-style";
        }
      }
      // console.log(className);
      return className;
    },
  },
  methods: {
    gotoPackagePage() {
      const currentPath = window.location.pathname;

      // Set the returnUrl value in session storage
      sessionStorage.setItem("returnUrl", currentPath);
      this.$router.push("/package");
    },
    logOut() {
      this.$store.dispatch("auth/logout");
      this.currentuserinfo = "";
      this.usermode = "";
      this.$router.push("/").catch(() => {
        "navbar";
      });
    },
    removeguestid() {
      localStorage.setItem(
        "guestid",
        "_" + Math.random().toString(36).substr(2, 9)
      );
    },
    searchChange(searchValue) {
      if (searchValue == null || searchValue == "") {
        this.$route.query.search = null;
      }
    },
    getuserbyId() {
      let id = this.$store.state.auth.user[0].customerId;
      if (id == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getCustomerDetailById(id)
          .then((response) => {
            this.currentuserinfo = response.data.Data[0];
            // console.log(this.currentuserinfo);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    getsellerbyId() {
      let id = this.$store.state.auth.user[0].sellerId;
      if (id == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getsellerbyId(id)
          .then((response) => {
            this.currentuserinfo = response.data.Data[0];
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.search-tog {
  width: 95%;
  position: relative;
  display: flex;
  margin: 0px auto;
}

.searchTerm {
  width: 100%;
  border: 3px solid #00b4cc;
  border-right: none;
  padding: 5px;
  height: 50px;
  border-radius: 5px 0 0 5px;
  outline: none;
  color: #9dbfaf;
}

.searchTerm:focus {
  color: #00b4cc;
}

.searchButton {
  width: 40px;
  height: 50px;
  border: 1px solid #00b4cc;
  background: #00b4cc;
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 20px;
}

.search-btn-style {
  padding: 6px;
  margin-left: 15px;
}
.start-header {
  opacity: 1;
  transform: translateY(0);
  padding: 10px 0;
  box-shadow: 0 10px 30px 0 rgba(138, 155, 165, 0.15);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.start-header.scroll-on {
  box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
  padding: 10px 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.start-header.scroll-on .navbar-brand img {
  height: 24px;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.navigation-wrap {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.navbar {
  padding: 0;
}
.navbar-brand img {
  height: 50px;
  width: auto;
  display: block;
  /* filter: brightness(10%); */
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.navbar-toggler {
  float: right;
  border: none;
  padding-right: 0;
}
.navbar-toggler:active,
.navbar-toggler:focus {
  outline: none;
}
.navbar-light .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 1px solid #0094da;
  transition: all 0.3s linear;
}
.navbar-light .navbar-toggler-icon:after,
.navbar-light .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 1px;
  background-color: #0094da;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
.navbar-light .navbar-toggler-icon:after {
  top: 8px;
}
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
}
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
}
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}
.nav-link {
  color: #212121 !important;
  font-weight: 500;
  transition: all 200ms linear;
}
.nav-item:hover .nav-link {
  color: #8167a9 !important;
}
.nav-item.active .nav-link {
  color: #777 !important;
}
.nav-link {
  position: relative;
  padding: 5px 0 !important;
  display: inline-block;
}
.nav-item:after {
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 2px;
  content: "";
  background-color: #8167a9;
  opacity: 0;
  transition: all 200ms linear;
}
.nav-item:hover:after {
  bottom: 0;
  opacity: 1;
}
.nav-item.active:hover:after {
  opacity: 0;
}
.nav-item {
  position: relative;
  transition: all 200ms linear;
}
.connectBtn {
  background-color: blue;
  color: white !important;
  padding: 6px 15px 6px 15px !important;
  border-radius: 3px;
  box-shadow: 1px 1px 7px #0000005c;
}

#contents {
  /*border: 1px solid;*/
  /* margin-top: 90px;
    margin-left: 113px; */
  margin-right: px;
}
#header-img {
  float: right;
  margin-right: 60px;
}
#header {
  font-size: 60px;
}
#contents #email {
  font-size: 22.15px;
  margin: 0px;
}
#email-box {
  border: 1px solid;
  width: 450px;
  height: 48px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  margin: 0px;
  margin-top: 11px;
}
#contents #pwd {
  font-size: 22.15px;
  margin: 0px;
  margin-top: 21px;
}

#pwd-box {
  border: 1px solid;
  width: 450px;
  height: 48px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  margin: 0px;
  margin-top: 11px;
}

.check {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 490px;
  padding: 0;
  margin: 0;
  margin-top: 0px;
  margin-bottom: 24px;
  font-size: 16px;
}
.check input[type="checkbox"] {
  margin: 2px;
  transform: scale(1.5);
  padding: 0px;
}
.check p {
  font-size: 17px;
  color: #00b628;
  font-weight: 500;
}

#login {
  width: 163px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #00b628;
  color: white;
  margin: 0px;
  border: none;
}
#login a {
  text-decoration: none;
}
#loginInput {
  width: 163px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #00b628;
  color: white;
  margin: 0px;
  border: none;
  cursor: pointer;
}

.acct {
  margin: 0px;
  margin-top: 20px;
  padding: 0px;
  font-size: 17px;
  font-weight: 500;
}
.acct-span {
  color: #00b628;
}
.acct-span a {
  color: #00b628;
  text-decoration: none;
}
</style>
