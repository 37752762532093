var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.ListAuction)?_c('div'):(_vm.ListAuction.length)?_c('div',{staticClass:"container mp-sec"},[_vm._m(0),_c('carousel',{attrs:{"responsive":{
        0: {
          items: 1,
          nav: false,
          dots: false,
          loop: false,
          autoplay: true,
          autoplayTimeout: 4000,
          autoplayHoverPause: true,
        },
        600: {
          items: 3,
          nav: false,
          dots: false,
          loop: false,
          autoplay: true,
          autoplayTimeout: 4000,
          autoplayHoverPause: true,
        },
      }}},_vm._l((_vm.ListAuction),function(list,index){return _c('div',{key:index},[_c('div',{staticClass:"new-main-tile"},[_c('div',[_c('router-link',{attrs:{"to":{
                name: 'CarDetails',
                params: { url_key: list.aucName },
              }}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":list.coverImage ? (_vm.imags + '/' + list.coverImage) : list.vehImage1 ? (_vm.imags + '/' + list.vehImage1) : '/assets/images/Noimage.jpg'}})])],1),_c('div',{staticClass:"content-wrap-new-tile"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-8"},[_c('div',{staticClass:"tile-set-l"},[_c('router-link',{attrs:{"to":{
                      name: 'CarDetails',
                      params: { url_key: list.aucName },
                    }}},[_c('h5',[_vm._v(" "+_vm._s(list.brandName)+" "),_c('span',{staticClass:"cModalname"},[_vm._v(" "+_vm._s(list.modelName)+" ")])])]),_c('p',{staticClass:"text-Overtflow"},[_vm._v("Model : "+_vm._s(list.manYear))])],1)]),_c('div',{staticClass:"col-4"},[_c('div',[_c('div',{staticClass:"new-tile-bid-btn"},[_c('router-link',{attrs:{"to":{
                        name: 'CarDetails',
                        params: { url_key: list.aucName },
                      }}},[_vm._v(" BID NOW ")])],1)])])]),_c('div',[_c('hr')]),_c('div',{staticClass:"tile-content-n"},[_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('div',[_c('p',{staticClass:"text-Overtflow"},[_c('i',{staticClass:"fa fa-road",attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(list.kmClocked)+" KM ")])])]),_c('div',{staticClass:"col-md-4"},[_c('div',[_c('p',{staticClass:"text-Overtflow"},[_c('i',{staticClass:"fa fa-gavel",attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(list.bidzCount)+" BIDS ")])])]),_c('div',{staticClass:"col-md-4"},[_c('div',[_c('p',{staticClass:"text-Overtflow"},[_c('i',{staticClass:"fa fa-map-marker",attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(list.locName)+" ")])])])])])])]),_c('div',[_c('div',[_c('button',{staticClass:"timer-button"},[_c('HomeCountdown',{staticClass:"test-btnz",attrs:{"deadline":list.actualMaturityDate}})],1)])])])])}),0)],1):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('h3',{staticClass:"mb-30"},[_vm._v("Auctions Ends Soon")])])
}]

export { render, staticRenderFns }