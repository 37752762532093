<template>
  <div>
    <div>
      <main class="page-content">
        <div class="account-page-area">
          <div class="container containerDash">
            <div class="row">
              <div class="col-lg-3 account_border-right">
                <sidebar-dashboard-customer></sidebar-dashboard-customer>
              </div>
              <div class="col-lg-9">
                <h3>Wishlist</h3>
                <div v-if="!items.length">
                  <notfound :checkdata="initialLoading"></notfound>
                  <!--<loader></loader>-->
                </div>
                <div v-else>
                  <section style="padding: 10px 0px">
                    <div class="bidz-container-list">
                      <div
                        class="pdtListingWrap"
                        v-for="item in items"
                        :key="'A' + item.vehId"
                      >
                        <div class="row">
                          <div class="col-md-8 bidborderright">
                            <div class="row">
                              <div class="bidlistDetailswrapwidth85">
                                <div class="bidlistDetailswrap">
                                  <router-link
                                    :to="{
                                      name: 'CarDetails',
                                      params: { url_key: item.aucName },
                                    }"
                                  >
                                    <h4>
                                      {{ item.brandName }}
                                      <span class="cModalname">
                                        {{ item.modelName }}
                                      </span>
                                    </h4></router-link
                                  >
                                </div>
                              </div>
                              <div class="bidlistDetailswrapwidth15">
                                <div>
                                  <div
                                    class="button-bidzzz active"
                                    @click="rmvfromWhishlist(item)"
                                  >
                                    <svg
                                      width="35px"
                                      height="25px"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g fill="none" fill-rule="evenodd">
                                        <path
                                          class="heart-stroke"
                                          d="M13.0185191,4.25291223 L12.9746137,4.25291223 C10.1097846,4.25291223 8.67188189,6.6128289 8.5182129,8.92335198 C8.39747298,10.6740809 8.73225185,12.8528876 14.0777375,18.4782704 C14.7127154,19.1080239 15.5654911,19.4695694 16.4596069,19.4880952 C17.3247917,19.4700909 18.1444718,19.0969678 18.7262246,18.4563177 C19.3189478,17.9074999 24.5052763,12.5894551 24.3570955,8.98921012 C24.2363556,6.42623084 22.123407,4.25291223 19.7525139,4.25291223 C18.5053576,4.22947431 17.3125171,4.76253118 16.4980242,5.70727948 C15.6177331,4.73767759 14.354699,4.20555668 13.04596,4.25291223 L13.0185191,4.25291223 Z"
                                          fill="#0090E3"
                                        />
                                        <path
                                          class="heart-full"
                                          d="M13.0185191,4.25291223 L12.9746137,4.25291223 C10.1097846,4.25291223 8.67188189,6.6128289 8.5182129,8.92335198 C8.39747298,10.6740809 8.73225185,12.8528876 14.0777375,18.4782704 C14.7127154,19.1080239 15.5654911,19.4695694 16.4596069,19.4880952 C17.3247917,19.4700909 18.1444718,19.0969678 18.7262246,18.4563177 C19.3189478,17.9074999 24.5052763,12.5894551 24.3570955,8.98921012 C24.2363556,6.42623084 22.123407,4.25291223 19.7525139,4.25291223 C18.5053576,4.22947431 17.3125171,4.76253118 16.4980242,5.70727948 C15.6177331,4.73767759 14.354699,4.20555668 13.04596,4.25291223 L13.0185191,4.25291223 Z"
                                          fill="red"
                                        />
                                        <path
                                          class="heart-lines"
                                          d="M26,4 L30.6852129,0.251829715"
                                          stroke="red"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                        />
                                        <path
                                          class="heart-lines"
                                          d="M2.314788,4 L7.00000086,0.251829715"
                                          stroke="red"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          transform="matrix(-1 0 0 1 10.314788 1)"
                                        />
                                        <path
                                          class="heart-lines"
                                          d="M27,12 L33,12"
                                          stroke="red"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                        />
                                        <path
                                          class="heart-lines"
                                          d="M0,12 L6,12"
                                          stroke="red"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          transform="matrix(-1 0 0 1 7 1)"
                                        />
                                        <path
                                          class="heart-lines"
                                          d="M24,19 L28.6852129,22.7481703"
                                          stroke="red"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                        />
                                        <path
                                          class="heart-lines"
                                          d="M4.314788,19 L9.00000086,22.7481703"
                                          stroke="red"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          transform="matrix(-1 0 0 1 14.314788 1)"
                                        />
                                      </g>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="row hidinglast2on768-991"
                              style="row-gap: 10px"
                            >
                              <div
                                class="col-md-4 col-sm-4 bidlistwidth50p bidlistwidthfor768-991"
                              >
                                <div class="">
                                  <div>
                                    <i
                                      class="fas fa-tachometer-alt pdtListico"
                                    ></i>
                                    <div class="pdtListicon">
                                      <!-- <span>Km Clocked </span> -->
                                      <p class="fontWeightbidlist">
                                        {{ item.kmClocked }} KM
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="col-md-4 col-sm-4 bidlistwidth50p bidlistwidthfor768-991"
                              >
                                <div class="">
                                  <div>
                                    <i
                                      class="fas fa-map-marker-alt pdtListico"
                                    ></i>
                                    <div class="pdtListicon">
                                      <!-- <span>Location</span> -->
                                      <p
                                        class="fontWeightbidlist main-tile-location"
                                      >
                                        {{ item.locName }}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                class="col-md-4 col-sm-4 bidlistwidth50p bidlistwidthfor768-991"
                              >
                                <div class="">
                                  <div>
                                    <div class="text-center">
                                      <span>Inc.</span>
                                      <span
                                        class="fontWeightbidlist"
                                        v-if="item.aucMinBid"
                                      >
                                        {{ item.aucMinBid | currency }}
                                      </span>
                                      <span class="fontWeightbidlist" v-else>
                                        Not Available
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                class="col-md-4 col-sm-4 bidlistwidth50p bidlistwidthfor768-991"
                              >
                                <div class="">
                                  <div>
                                    <i
                                      v-if="item.bidStatus"
                                      class="far fa-clock pdtListico greenIcon"
                                    ></i>

                                    <i
                                      v-else
                                      class="far fa-calendar-alt pdtListico greenIcon"
                                    ></i>
                                    <div class="pdtListicon">
                                      <p v-if="!item.bidStatus">
                                        {{ item.aucDate | formatDate }}
                                      </p>
                                      <p
                                        v-else-if="
                                          item.actualMaturityDate < datenow
                                        "
                                      >
                                        AUCTION HAS ENDED
                                      </p>

                                      <p
                                        v-else
                                        class="fontWeightbidlist greenIcon"
                                      >
                                        <Countdown
                                          :deadline="item.actualMaturityDate"
                                        ></Countdown>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                class="col-md-4 col-sm-4 bidlistwidth50p bidlistwidthfor768-991"
                              >
                                <div class="">
                                  <div>
                                    <i
                                      class="far fa-calendar-alt pdtListico greenIcon"
                                    ></i>
                                    <div class="pdtListicon">
                                      <!-- <span>End Date</span> -->
                                      <p class="fontWeightbidlist greenIcon">
                                        {{ item.aucMatDate | formatDate }} IST
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                class="col-md-4 col-sm-4 bidlistwidth50p bidlistwidthfor768-991"
                              >
                                <div class="">
                                  <div>
                                    <div class="text-center">
                                      <p class="bidzcarnum">
                                        {{ item.vehRegNo | truncate(5) }} ****
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="text-center">
                              <div class="marginbidtop">
                                <span class="spanzz">CURRENT BID AMOUNT</span>
                                <h4 v-if="!item.latestBidAmount">₹ 0</h4>
                                <h4 v-else>
                                  {{ item.latestBidAmount | currency }}
                                </h4>

                                <div class="row" v-if="item.bidStatus">
                                  <div class="col-md-6">
                                    <div>
                                      <div class="marginbidtop">
                                        <b-button
                                          v-if="item.latestBidAmount"
                                          v-b-modal.modalbids
                                          class="bidBtn"
                                          @click="sendInfos(item)"
                                          >BID NOW</b-button
                                        >
                                        <b-button v-else class="bidBtn"
                                          >BID NOW</b-button
                                        >
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div>
                                      <div class="marginbidtop">
                                        <span class="spanzz"
                                          >NUMBER OF BIDS</span
                                        >
                                        <p class="bidColorbtn">
                                          {{ item.bidzCount }} BIDS
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="overflow-auto">
                        <div class="mt-3">
                          <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                            align="center"
                            pills
                            first-number
                            last-number
                          ></b-pagination>
                        </div>
                      </div>
                      <div>
                        <b-modal
                          id="modalbids"
                          @show="resetModal"
                          @hidden="resetModal"
                          ref="modal"
                          hide-footer
                          v-if="currentUser"
                        >
                          <form ref="form" v-if="issuccess != 'Success'">
                            <div
                              v-if="
                                selectedUserwh.latestBidAmount <
                                selectedUserwh.aucStartPrice
                              "
                            >
                              <p for="bidamount" class="bidamountzz">
                                Enter your bid amount
                              </p>

                              <div
                                class="bidsentry"
                                style="
                                  display: inline-flex;
                                  flex-direction: row;
                                  align-items: baseline;
                                "
                              >
                                <p
                                  for="bidamount"
                                  class="bidamountzz"
                                  style="margin-right: 1rem"
                                >
                                  {{ selectedUserwh.aucStartPrice | currency }}
                                  +
                                </p>
                                <input
                                  type="test"
                                  class="form-control"
                                  id="bidamount"
                                  v-model="formattedBidAmount"
                                  name="bidamont"
                                  style="width: 55%"
                                />
                              </div>
                            </div>
                            <div v-else>
                              <p for="bidamount" class="bidamountzz">
                                Enter your bid amount
                              </p>

                              <div
                                class="bidsentry"
                                style="
                                  display: inline-flex;
                                  flex-direction: row;
                                  align-items: baseline;
                                "
                              >
                                <p
                                  for="bidamount"
                                  class="bidamountzz"
                                  style="margin-right: 1rem"
                                >
                                  {{
                                    selectedUserwh.latestBidAmount | currency
                                  }}
                                  +
                                </p>
                                <input
                                  type="test"
                                  class="form-control"
                                  id="bidamount"
                                  v-model="formattedBidAmount"
                                  name="bidamont"
                                  style="width: 55%"
                                />
                              </div>
                            </div>

                            <button
                              type="button"
                              :disabled="isLoading"
                              class="btn subBtnzz"
                              @click="bidentry(selectedUserwh)"
                              style="width: 100%; margin-top: 10px"
                            >
                              <span v-show="!isLoading"> SUBMIT</span>
                              <span v-show="isLoading"
                                >Submiting...
                                <i class="fa fa-spinner fa-pulse"></i
                              ></span>
                            </button>
                            <div class="form-group" v-if="message == 'Success'">
                              <div class="alert alert-warning" role="alert">
                                {{ message }}
                              </div>
                            </div>
                            <div class="form-group" v-else-if="message">
                              <div class="alert alert-warning" role="alert">
                                {{ message }}
                              </div>
                            </div>
                          </form>
                          <form v-else-if="issuccess == 'Success'">
                            <successMessage
                              :aucName="selectedUserwh.aucName"
                              :bidAmount="selectedUserwh.aucMinBid"
                            ></successMessage>
                          </form>
                        </b-modal>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import userService from "../services/user.service";
import userinformation from "../models/localstorage";
import Config from "../config";
import axios from "axios";
import moment from "moment";
export default {
  name: "ProductCard",

  data() {
    return {
      lists: [],
      perPage: 10,
      currentPage: 1,
      path: Config.BASE_URL_IMG,
      message: "",
      modalShow: false,
      bid: "",
      customerId: "",
      st: "",
      selectedUserwh: "",
      isLoading: false,
      issuccess: "",
      initialLoading: true,
      minimumBidAmount: null,
      datenow: new Date(),
      maximumBidAmount: null,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    items() {
      return this.lists.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
    totalRows() {
      return this.lists.length;
    },
    formattedBidAmount: {
      get() {
        const bidAmount = this.selectedUserwh.aucMinBid;

        if (bidAmount === null || bidAmount === "" || isNaN(bidAmount)) {
          return 0;
        } else {
          return bidAmount?.toLocaleString("en-IN");
        }
      },
      set(value) {
        const updatedBidAmount = this.selectedUserwh.aucMinBid;
        if (
          updatedBidAmount === null ||
          updatedBidAmount === "" ||
          isNaN(updatedBidAmount)
        ) {
          this.selectedUserwh.aucMinBid = parseFloat(value.replace(/,/g, ""));
        } else {
          this.selectedUserwh.aucMinBid = parseFloat(value.replace(/,/g, ""));
        }
      },
    },
  },

  mounted() {
    if (!this.currentUser) {
      this.$router.push("/").catch(() => {
        "whishlist";
      });
    } else {
      this.st = userinformation[0];
      this.getCarList();
    }
  },
  created() {
    if (this.loggedIn) {
      if (
        !this.$store.state.auth.user[0].customerId == "" ||
        !this.$store.state.auth.user[0].customerId == " "
      ) {
        this.$router.push("/mywishlist").catch(() => {
          "home";
        });
      } else {
        this.$router.push("/").catch(() => {
          "home";
        });
      }
    }
  },
  methods: {
    closeModal() {
      var self = this;

      setTimeout(function () {
        self.hideModal();
      }, 2000);
    },
    hideModal() {
      this.issuccess = "";
      this.$refs["modal"].hide();
      location.reload();
    },
    resetModal() {
      this.message = "";
      this.bid = "";
      this.message = null;
      this.bid = null;
      this.issuccess = "";
      this.selectedUserwh.aucMinBid = this.minimumBidAmount;
    },
    getCarList() {
      let tokens = this.st.token;
      let customerId = this.st.customerId;
      axios({
        method: "Get",
        url: Config.BASE_URL + "?sp=getCustomerWishlists&custId=" + customerId,
        headers: { token: tokens },
      })
        .then((resp) => {
          this.lists = resp.data.Data;
          this.initialLoading = false;
        })
        .catch((err) => {
          console.log(err.response);
          this.initialLoading = false;
        });
    },
    sendInfos(item) {
      if (!this.currentUser) {
        this.gotologin();
      } else {
        // console.log(item + "lists");
        this.selectedUserwh = item;
        this.minimumBidAmount = item.aucMinBid;
        this.maximumBidAmount = item.aucMaxBid;
      }
    },

    bidentry(selectedUserwh) {
      this.isLoading = true;
      if (
        this.st &&
        this.selectedUserwh.aucMinBid !== "" &&
        this.selectedUserwh.aucMinBid !== null
      ) {
        let cusid = this.st.customerId;
        let bidamount = parseInt(this.selectedUserwh.aucMinBid);
        if (
          isNaN(bidamount) ||
          bidamount <= 0 ||
          bidamount < this.minimumBidAmount
        ) {
          this.message =
            "Bid amount should be greater than or equal to minimum bid amount" +
            " - " +
            this.minimumBidAmount;
          this.isLoading = false;
        } else if (bidamount > this.maximumBidAmount) {
          this.message =
            "Bid amount should be less than or equal to maximum bid amount" +
            " - " +
            this.maximumBidAmount;
          this.isLoading = false;
        } else {
          userService
            .addbid(selectedUserwh, cusid, bidamount)
            .then((response) => {
              this.message = response.data.Message;
              this.isLoading = false;
              this.issuccess = this.message;
              this.closeModal();
              this.getCarList();
            })
            .catch((error) => {
              this.isLoading = false;
              this.message =
                (error.response && error.response.data.Message) ||
                error.message ||
                error.toString();
              if (this.message == "Purchase a package to continue ") {
                setTimeout(() => {
                  window.location = "/package";
                }, 3000);
              }
              if(this.message =='Someone else might have placed a bid, Please refresh the page'){
                setTimeout(() => {
                  location.reload();
                }, 3000);
              }
            });
        }
      } else {
        this.isLoading = false;
        this.message = "Field is required";
      }
    },

    rmvfromWhishlist(item) {
      userService
        .rmvfromWhishlist(item, this.st.customerId)
        .then((response) => {
          this.makeToast("success", "Removed from wishlist");
          if (response.data.Message == "Success") {
            this.makeToast("success", "Removed from wishlist");
          }
          setTimeout(() => {
            location.reload();
          }, Config.pageRedirectDelay);
        });
    },
    makeToast(variant, body_content) {
      let tittleMessage = variant;
      if (variant == "danger") {
        tittleMessage = "Error";
      }
      if (variant == "success") {
        tittleMessage = "Success";
      }
      if (variant == "warning") {
        tittleMessage = "Warning";
      }
      this.$bvToast.toast(body_content, {
        title: `${tittleMessage || "default"}`,
        variant: variant,
        autoHideDelay: Config.toastDelay,
        //solid: true,
        toaster: "b-toaster-bottom-right",
        appendToast: true,
      });
    },
  },
  filters: {
    dateISO(value) {
      if (value) {
        var d = new Date(value);
        //  d = d.toLocaleString("en-US", { timeZone:  Config.CurrNttimezone });
        // var date = new Date(d);
        return d.toISOString();
      }
    },
    truncate: function (data, num) {
      const reqdString = data.split("").slice(0, num).join("");
      return reqdString;
    },
    formatDate: function (value) {
      if (value) {
        return moment(String(value)).format("D-MMM-YYYY h:mm a");
      }
    },
    currency: function (value) {
      if (typeof value !== "number") {
        return value;
      }
      let country = Config.countryCode;
      let crncy = Config.curency;
      let FractionDigits = Config.minFractionDigits;
      const formatter = new Intl.NumberFormat(country, {
        style: "currency",
        currency: crncy,
        minimumFractionDigits: FractionDigits,
      });
      return formatter.format(value);
    },
  },
};
</script>

<style scoped>
.loading-overlay {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(100, 100, 100, 0.5);
}

i.fa.fa-cog {
  font-size: 3em;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.bidBtn {
  background-color: #0d082fd6 !important;
  padding: 4px 20px !important;
}
.marginbidtop {
  margin-top: 0px !important;
}
.marginbidtop h4 {
  font-size: 20px;
}

.spanzz {
  font-size: 12px;
}
.bidColorbtn {
  padding: 3px 20px;
}
.bidBtn {
  padding: 3px 6px;
}
.marginbidtop {
  margin-top: 5px;
}
.heart-stroke {
  fill: none;
  stroke: #666;
  stroke-width: 2px;
  opacity: 1;
  transform-origin: center center;
}
.button-bidzzz.active .heart-stroke {
  opacity: 0;
}
.heart-full {
  opacity: 0;
  transform-origin: 50% 50%;
}
.button-bidzzz.active .heart-full {
  opacity: 1;
}
.heart-lines {
  stroke-width: 2px;
  display: none;
}

.button-bidzzz:not(.active):hover .heart-stroke {
  -webkit-animation: pulse 1s ease-out infinite;
  animation: pulse 1s ease-out infinite;
}

.button-bidzzz.animate .heart-full {
  -webkit-animation: heart 0.35s;
  animation: heart 0.35s;
}
.button-bidzzz.animate .heart-lines {
  -webkit-animation: lines 0.2s ease-out forwards;
  animation: lines 0.2s ease-out forwards;
  display: block;
}

@-webkit-keyframes lines {
  0% {
    stroke-dasharray: 6;
    stroke-dashoffset: 16;
  }
  100% {
    stroke-dasharray: 13;
    stroke-dashoffset: 18;
  }
}

@keyframes lines {
  0% {
    stroke-dasharray: 6;
    stroke-dashoffset: 16;
  }
  100% {
    stroke-dasharray: 13;
    stroke-dashoffset: 18;
  }
}
@-webkit-keyframes heart {
  0% {
    transform: scale(1);
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(1.2);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  35% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  75% {
    transform: scale(1.1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heart {
  0% {
    transform: scale(1);
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(1.2);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  35% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  75% {
    transform: scale(1.1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@-webkit-keyframes pulse {
  0% {
    opacity: 1;
    transform-origin: center center;
    transform: scale(1);
  }
  50% {
    opacity: 0.6;
    transform: scale(1.15);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes pulse {
  0% {
    opacity: 1;
    transform-origin: center center;
    transform: scale(1);
  }
  50% {
    opacity: 0.6;
    transform: scale(1.15);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.subBtnzz {
  background-image: linear-gradient(to bottom right, #1d1b2b, #004a6d);

  color: white;
  font-weight: 600;
}
.bidamountzz {
  font-size: 20px;
  font-weight: 600;
}
.heart-stroke {
  fill: none;
  stroke: #ddd;
  stroke-width: 2px;
  opacity: 1;
  transform-origin: center center;
}
.button-bidzzz.active .heart-stroke {
  opacity: 0;
}
.heart-full {
  opacity: 0;
  transform-origin: 50% 50%;
}
.button-bidzzz.active .heart-full {
  opacity: 1;
}
.heart-lines {
  stroke-width: 2px;
  display: none;
}

.button-bidzzz:not(.active):hover .heart-stroke {
  -webkit-animation: pulse 1s ease-out infinite;
  animation: pulse 1s ease-out infinite;
}

.button-bidzzz.animate .heart-full {
  -webkit-animation: heart 0.35s;
  animation: heart 0.35s;
}
.button-bidzzz.animate .heart-lines {
  -webkit-animation: lines 0.2s ease-out forwards;
  animation: lines 0.2s ease-out forwards;
  display: block;
}

@-webkit-keyframes lines {
  0% {
    stroke-dasharray: 6;
    stroke-dashoffset: 16;
  }
  100% {
    stroke-dasharray: 13;
    stroke-dashoffset: 18;
  }
}

@keyframes lines {
  0% {
    stroke-dasharray: 6;
    stroke-dashoffset: 16;
  }
  100% {
    stroke-dasharray: 13;
    stroke-dashoffset: 18;
  }
}
@-webkit-keyframes heart {
  0% {
    transform: scale(1);
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(1.2);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  35% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  75% {
    transform: scale(1.1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heart {
  0% {
    transform: scale(1);
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(1.2);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  35% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  75% {
    transform: scale(1.1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@-webkit-keyframes pulse {
  0% {
    opacity: 1;
    transform-origin: center center;
    transform: scale(1);
  }
  50% {
    opacity: 0.6;
    transform: scale(1.15);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes pulse {
  0% {
    opacity: 1;
    transform-origin: center center;
    transform: scale(1);
  }
  50% {
    opacity: 0.6;
    transform: scale(1.15);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
