import axios from 'axios';
import config from '../config';
const API_URL = config.BASE_URL;

class AuthService {
  async login(user) {
    const response = await axios
      .post(API_URL, {
        sp: 'getCustomerAuth',
        username: user.username,
        password: user.password
      }
      // , {
      //   headers: {
      //     'Access-Control-Allow-Origin': '*',
      //     'Access-Control-Allow-Headers': 'accept, content-type',
      //     'Access-Control-Allow-Methods': '*'
      //   }
      // }

      );
    if (response.data.Data[0].token) {
      // this.$cookies.set('user', JSON.stringify(response.data.Data));
      localStorage.setItem('user', JSON.stringify(response.data.Data));
    }
    return response.data;
  }
  async vendorlogin(user) {
    const response = await axios
      .post(API_URL, {
        sp: 'getSellerAuth',
        username: user.username,
        password: user.password
      });
    if (response.data.Data[0].token) {
      // this.$cookies.set('user', JSON.stringify(response.data.Data));
      localStorage.setItem('user', JSON.stringify(response.data.Data));

    }
    return response.data;
  }

  logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('guestid');

  }

  register(user) {
    return axios.post(API_URL, {
      sp: 'getCustomerRegisterOtp',
      fullName: user.username,
      email: user.email,
      phone: user.phone,
      password: user.password,
      termsChecked: user.termsChecked,
      status: "",
    });
  }
  vendorregister(user) {
    return axios.post(API_URL, {
      sp: 'getSellerRegisterOtp',
      sellerName: user.username,
      sellerEmail: user.email,
      sellerPhone: user.phone,
      sellerPassword: user.password,
      idProofImage: user.images,
      gstNumber: user.gstnumber,
      termsChecked: user.termsChecked

    });
  }



}

export default new AuthService();