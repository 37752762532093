<template>
  <div>
    <div>
      <section class="choose-package" style="padding: 10px 0px">
        <div class="container">
          <div class="row c-gutter-60">
            <div
              class="col-lg-4 col-12"
              v-for="(pakagel, index) in deadline"
              :key="index"
            >
              <div class="pricing-plan hero-bg rounded">
                <div class="plan-name bg-maincolor">
                  <h4 class="text-uppercase" style="color: white">
                    {{ pakagel.packName }}
                  </h4>
                  <div class="bid-tickets">
                    <!-- <span class="bid-ticket-count">{{
                    pakagel.packAmount | currency
                    }}</span> -->

                    <span
                      v-if="pakagel.isRedeemable == true"
                      style="font-size: 10px; font-size: 17px; color: white"
                      >(Package is redeemed on winnings *)
                    </span>
                  </div>
                </div>
                <div class="plan-features">
                  <ul class="list-bordered">
                    <p>{{ pakagel.packageMessage }}</p>
                    <li>
                      <span> {{ pakagel.packAuctions }}</span>
                      <br />
                      Auctions
                    </li>
                  </ul>
                  <ul class="list-bordered">
                    <li>
                      {{ pakagel.packagValidityInDays }} Day(s)
                      <br />
                      Pack validity
                    </li>
                  </ul>
                </div>
                <div class="price-wrap d-flex">
                  <!-- <span class="plan-sign small-text">₹</span> -->
                  <span class="plan-price color-main2">{{
                    pakagel.packAmount | currency
                  }}</span>
                  <span style="font-size: 14px; margin-top: 20px">
                    +GST({{ pakagel.gstPackAmount | currency }})</span
                  >
                </div>

                <div class="">
                  <button @click="purchase(pakagel)" class="btn btn-maincolor">
                    Buy
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div v-if="modelShow == true">
            <div class="ebcf_modal">
              <div class="ebcf_modal-content">
                <span class="ebcf_close" @click="modelShow = false"
                  >&times;</span
                >
                <div>
                  <div class="form-group" v-if="message == 'Success'">
                    <div
                      class="text-center"
                      style="background-color: #28e007; padding: 20px 0px"
                    >
                      <div>
                        <i class="fa fa-check" style="color: white"></i>
                        <h5 style="color: white">Success</h5>
                      </div>
                    </div>
                    <div class="text-center" style="padding: 10px 0px">
                      <p>
                        Purchase is {{ message }} check your profile more
                        details
                      </p>
                    </div>
                  </div>
                  <div class="form-group" v-else-if="message">
                    <div class="alert alert-warning" role="alert">
                      {{ message }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import userService from "../services/user.service";
import moment from "moment";
import Config from "../config";
export default {
  name: "package",
  props: ["order_details", "customer_details", "deadline"],

  data() {
    return {
      pakagel: "",
      message: "",
      resetModal: "",
      paymentInfo: "",
      script: `https://checkout.razorpay.com/v1/checkout.js`,
      modelShow: false,
    };
  },

  // created() {
  //   // Retrieve the returnUrl value from session storage
  //   const returnUrl = sessionStorage.getItem("returnUrl");

  //   // if (returnUrl) {
  //   //   // Use the returnUrl value for your logic, e.g., redirect to it
  //   //   console.log("Return URL:", returnUrl);
  //   // } else {
  //   //   // The returnUrl is not set in session storage
  //   //   console.log("Return URL is not set.");
  //   // };

  // },
  filters: {
    truncate: function (data, num) {
      const reqdString = data.split("").slice(0, num).join("");
      return reqdString;
    },
    currency: function (value) {
      if (typeof value !== "number") {
        return value;
      }
      let country = Config.countryCode;
      let crncy = Config.curency;
      let FractionDigits = Config.minFractionDigits;
      const formatter = new Intl.NumberFormat(country, {
        style: "currency",
        currency: crncy,
        minimumFractionDigits: FractionDigits,
      });
      return formatter.format(value);
    },
    formatDate: function (value) {
      if (value) {
        return moment(String(value)).format("D-MMM-YYYY");
      }
    },
  },

  methods: {
    async purchase(pakagel) {
      //console.log(pakagel);
      //alert(pakagel.packid);
      sessionStorage.setItem("returnUrl", "/MyAccount");

      var self = this;
      const result = await this.loadRazorPay();
      if (!result) {
        alert("Failed to load razorpay script");
        this.makeToast("danger", "! Oops somthings went wrong ");
        return;
      }
      this.st = this.$store.state.auth.user;
      let cusid = this.st[0].customerId;

      userService
        .purchasePackage(pakagel, cusid, "customer")
        .then((response) => {
          this.paymentInfo = response.data.Data[0];
          //console.log(response.data.Data[0]);
          this.message = response.data.Message;
          if (this.message == "Success" && this.paymentInfo.rp_token != null) {
            // alert("Success");
            //  let rpayTokenId =  JSON.parse(this.paymentInfo.rp_token);
            //console.log();

            const options = {
              key: Config.rpayKey,
              amount: this.paymentInfo.amount,
              currency: Config.curency,
              name: this.paymentInfo.cust_name,
              description: this.paymentInfo.packageName,
              order_id: this.paymentInfo.rp_token,
              image: `/assets/images/menu/logo/2.png`,
              handler: (response) => {
                // console.log(response.razorpay_payment_id);
                //   console.log(response.razorpay_order_id);
                //   console.log(response.razorpay_signature);
                // It is function. executed on success
                userService
                  .updPackageOrder(response)
                  .then((response) => {
                    this.message = response.data.Message;
                    if (this.message == "Success") {
                      self.$router.push("/paymentSuccess").catch(() => {
                        "paymentSuccess";
                      });
                    }
                  })
                  .catch((error) => {
                    //alert('hi');
                    this.isLoading = false;
                    let resMessage =
                      (error.response && error.response.data.Message) ||
                      error.message ||
                      error.toString();

                    this.makeToast("danger", resMessage);
                  });
              },

              prefill: {
                name: this.paymentInfo.cust_name,
                email: this.paymentInfo.cust_email,
                contact: this.paymentInfo.cust_phone,
              },
              theme: {
                color: "#3399cc",
              },
            };

            const rzp1 = new window.Razorpay(options);
            rzp1.on("payment.failed", function (response) {
              console.log(response.error.code);
              // alert(response.error.description);
              // alert(response.error.source);
              // alert(response.error.step);
              // alert(response.error.reason);
              // alert(response.error.metadata.order_id);
              // alert(response.error.metadata.payment_id);
            });
            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
          }
        })
        .catch((error) => {
          this.isLoading = false;
              this.message =
                (error.response && error.response.data.Message) ||
                error.message ||
                error.toString();

              if (this.message == "Upgrade to a higher package to proceed ") {
                this.makeToast("warning", this.message);
              }
        });
    },
    async loadRazorPay() {
      return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = this.script;
        script.onload = () => {
          resolve(true);
        };
        script.onerror = () => {
          resolve(false);
        };
        document.body.appendChild(script);
      });
    },
    closeModal() {
      var self = this;

      setTimeout(function () {
        self.hideModal();
      }, 2000);
    },
    hideModal() {
      this.modelShow = false;
    },
    makeToast(variant, body_content) {
      let tittleMessage = variant;
      if (variant == "danger") {
        tittleMessage = "Error";
      }
      if (variant == "success") {
        tittleMessage = "Success";
      }
      if (variant == "warning") {
        tittleMessage = "Warning";
      }

      this.$bvToast.toast(body_content, {
        title: `${tittleMessage || "default"}`,
        variant: variant,
        autoHideDelay: 5000,
        //solid: true,
        toaster: "b-toaster-bottom-right",
        appendToast: true,
      });
    },
  },
};
</script>
<style scoped>
section {
  padding: 60px 0;
  min-height: 100vh;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
}

a,
a:active,
a:focus {
  color: #6f6f6f;
  text-decoration: none;
  transition-timing-function: ease-in-out;
  -ms-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  -ms-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
img {
  max-width: 100%;
  height: auto;
}
section {
  background-color: #f7f6fb;
  color: #808080;
}
.bg-maincolor {
  background-image: -webkit-linear-gradient(left, #2165a9 0%, #3197d4 100%);
  background-image: linear-gradient(to right, #2165a9 0%, #3197d4 100%);
}

.bg-maincolor2 {
  background-image: -webkit-linear-gradient(left, #2165a9 0%, #3197d4 100%);
  background-image: linear-gradient(to right, #2165a9 0%, #3197d4 100%);
}

.bg-maincolor3 {
  background-image: -webkit-linear-gradient(left, #208291 0%, #32b9c2 100%);
  background-image: linear-gradient(to right, #208291 0%, #32b9c2 100%);
}

.color-main {
  border-color: #5b83cd !important;
  color: #5b83cd !important;
}

.color-main2 {
  border-color: #0094da !important;
  color: #0094da !important;
}

.color-main3 {
  border-color: #2165a9 !important;
  color: #2165a9 !important;
}

.color-main4 {
  border-color: #3197d4 !important;
  color: #3197d4 !important;
}

.color-main5 {
  border-color: #208291 !important;
  color: #208291 !important;
}

.color-main6 {
  border-color: #32b9c2 !important;
  color: #32b9c2 !important;
}

.color-main7 {
  border-color: #8073b2 !important;
  color: #8073b2 !important;
}
.list-bordered {
  list-style: none;
  overflow: hidden;
  padding: 0;
}

.list-bordered li {
  border-bottom: 1px solid #e1e1e1;
  border-top: 1px solid #e1e1e1;
  padding-bottom: 15px;
  padding-top: 15px;
  font-size: 18px;
}

.list-bordered li + li {
  border-top: none;
}

.list-bordered.no-top-border > li:first-child {
  border-top: none;
}

.list-bordered.no-bottom-border > li:last-child {
  border-bottom: none;
}

.small-text {
  color: #2770b2;
  font-size: 38px;
  font-weight: 500;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}
h5.special-heading {
  font-size: 24px;
  font-weight: 200;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

@media (max-width: 575px) {
  h5.special-heading {
    font-size: 20px;
  }
}

.special-heading {
  font-size: 38px;
  font-weight: 900;
  line-height: 1;
  word-wrap: break-word;
}

.special-heading.text-lowercase {
  font-size: 62px;
  font-weight: 300;
  line-height: 1.1;
  margin-bottom: 0;
}
h5.special-heading {
  letter-spacing: 0.2em;
  margin: -3px 0 0;
}
.special-heading + h1 {
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #5d5d5d;
  font-size: 3vw;
  margin: 20px 0;
}
@media (max-width: 575px) {
  .special-heading + h1 {
    font-size: 35px;
  }
}

.btn {
  border: 0;
  border-radius: 5px;
  box-sizing: border-box;
  height: 47px;
  width: 100%;
  /* line-height: 1.2; */
  color: #fff;
  font-size: 18px;
  margin-top: 30px;
  margin-bottom: 20px;
}
.btn-maincolor,
.btn-maincolor:not(:disabled):not(.disabled):active {
  background-image: -webkit-linear-gradient(left, #5b83cd 0%, #0094da 100%);
  background-image: linear-gradient(to right, #5b83cd 0%, #0094da 100%);
}

.btn-maincolor2,
.btn-maincolor2:not(:disabled):not(.disabled):active {
  background-image: -webkit-linear-gradient(left, #2165a9 0%, #3197d4 100%);
  background-image: linear-gradient(to right, #2165a9 0%, #3197d4 100%);
}

.btn-maincolor3,
.btn-maincolor3:not(:disabled):not(.disabled):active {
  background-image: -webkit-linear-gradient(left, #208291 0%, #32b9c2 100%);
  background-image: linear-gradient(to right, #208291 0%, #32b9c2 100%);
}

.btn-outline-maincolor:hover span,
.btn-outline-maincolor2:hover span,
.btn-outline-maincolor3:hover span {
  background: transparent;
  color: #fff;
}

.btn-outline-maincolor span,
.btn-outline-maincolor2 span,
.btn-outline-maincolor3 span {
  background-color: #fff;
}

.btn-outline-maincolor,
.btn-outline-maincolor:not(:disabled):not(.disabled):active {
  background-image: -webkit-linear-gradient(left, #5b83cd 0%, #0094da 100%);
  background-image: linear-gradient(to right, #5b83cd 0%, #0094da 100%);
  color: #c280bf;
}

.btn-outline-maincolor2,
.btn-outline-maincolor2:not(:disabled):not(.disabled):active {
  background-image: -webkit-linear-gradient(left, #2165a9 0%, #3197d4 100%);
  background-image: linear-gradient(to right, #2165a9 0%, #3197d4 100%);
  color: #3197d4;
}

.btn-outline-maincolor3,
.btn-outline-maincolor3:not(:disabled):not(.disabled):active {
  background-image: -webkit-linear-gradient(left, #208291 0%, #32b9c2 100%);
  background-image: linear-gradient(to right, #208291 0%, #32b9c2 100%);
  color: #32b9c2;
}
.pricing-plan {
  box-shadow: 0 0px 10px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 25px;
  margin-top: 25px;
  padding: 40px 40px 10px;
  text-align: center;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.pricing-plan .price-wrap {
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-align: center;
  -webkit-box-pack: center;
  font-size: 20px;
  -webkit-justify-content: center;
  justify-content: center;
  line-height: 0.7;
  margin: 42px 0 3px;
}

.pricing-plan .price-wrap[class*="bg-"] {
  margin-bottom: 0;
  margin-top: 50px;
  padding: 30px 30px 10px;
}

.pricing-plan .price-wrap .plan-price {
  font-size: 40px;
  font-weight: 700;
  padding: 0 6px;
}

.pricing-plan [class*="bg-"] {
  margin-left: -41px;
  margin-right: -41px;
  padding: 10px 30px 27px;
}

.pricing-plan .plan-name[class*="bg-"] {
  margin-top: -40px;
  padding-top: 30px;
}

.pricing-plan .plan-name h3 {
  font-size: 24px;
  letter-spacing: 0.2em;
  line-height: 1;
  color: #ffffff;
}

@media (min-width: 1200px) {
  .pricing-plan {
    padding: 57px 60px 10px;
  }

  .pricing-plan [class*="bg-"] {
    margin-left: -61px;
    margin-right: -61px;
  }

  .pricing-plan .plan-name[class*="bg-"] {
    margin-top: -57px;
  }

  .pricing-plan.plan-featured [class*="bg-"] {
    margin-left: -60px;
    margin-right: -60px;
  }
}

.pricing-plan .plan-features {
  margin: 38px 0 0;
}

.pricing-plan .plan-button {
  position: relative;
  top: 38px;
}

.pricing-plan.plan-featured {
  border: 5px solid #2165a9;
  -webkit-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  transform: translateY(-10px);
}

.pricing-plan.plan-featured .plan-button {
  top: 48px;
}

.pricing-plan.rounded .plan-name {
  border-radius: 5px 5px 0 0;
}

.plan-desc {
  -webkit-box-pack: justify;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin-top: 40px;
}

@media (max-width: 767px) {
  .plan-desc {
    -webkit-justify-content: space-around !important;
    justify-content: space-around !important;
  }
}

@media (max-width: 991px) {
  .plan-desc {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
  }
}

@media (min-width: 991px) and (max-width: 1200px) {
  .plan-desc {
    margin: 40px -15px 0;
  }
}

.plan-desc h4 {
  font-weight: 200;
  letter-spacing: 5px;
  line-height: 1.3;
  margin: 0;
}

.plan-desc .price-icon {
  -webkit-transform: translateX(7%);
  -ms-transform: translateX(7%);
  transform: translateX(7%);
}

.plan-desc p {
  line-height: 0;
}

.plan-desc .plan-content {
  margin-top: 10px;
}

@media (max-width: 767px) {
  .plan-desc .plan-content {
    margin-top: 25px;
  }
}

.pricing-plan {
  box-shadow: 0 0px 10px 0px rgba(0, 0, 0, 0.1);
  background: #ffffff;
}

@media (min-width: 991px) {
  .pricing-plan.pricing-plan:hover {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
}
/* The Modal (background) */
.ebcf_modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  font-size: 60px;
}

/* Modal Content */
.ebcf_modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 350px;
  height: 280px;
  border-radius: 5px;
}

/* The Close Button */
.ebcf_close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.ebcf_close:hover,
.ebcf_close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
</style>
