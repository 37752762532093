<template>
  <div class="review-list" style="margin-top: 45px; padding: 20px">
    <h3>Reviews</h3>
    <div class="row">
      <div
        v-for="(review, index) in Reviews"
        :key="index"
        class="col-md-3 mb-4"
      >
        <div class="card h-100">
          <img
            :src="
              review.vehImage1
                ? path + review.vehImage1
                : '/public/assets/images/NoImage.jpg'
            "
            class="card-img-top auction-image"
            alt="Auction Image"
          />
          <div class="card-body">
            <h5 class="card-title">
              <strong>{{ review.userName }}</strong>
            </h5>
            <h6 class="card-subtitle mb-2 text-muted">
              {{ review.aucName }}
            </h6>
            <vue-star-rating
              :rating="review.Rating"
              :read-only="true"
              :star-size="20"
              :show-rating="false"
            ></vue-star-rating>
            <p class="card-text mt-2">
              {{ review.Comment }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueStarRating from "vue-star-rating";
import userService from "../services/user.service";
import Config from "@/config";

export default {
  components: {
    "vue-star-rating": VueStarRating,
  },
  data() {
    return {
      Reviews: [],
      path: Config.BASE_URL_IMG,
    };
  },
  created() {
    this.getUserReview();
  },
  methods: {
    getUserReview() {
      userService
        .addUserReview("GET", -1, "", "", -1, "All")
        .then((response) => {
          this.Reviews = response.data.Data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style scoped>
.review-list {
  padding: 20px; /* Add spacing around the entire review list */
}

.card {
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-img-top {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

.card-body {
  text-align: center;
  padding: 15px;
}

h5.card-title {
  margin-bottom: 10px;
}

p.card-text {
  font-size: 14px;
  color: #555;
}

.review-list .row {
  justify-content: space-between;
}
</style>
