<template>
  <div>
    <singleslider></singleslider>
    <!-- <div v-if="loggedIn == f alse">
      <homeloginsection></homeloginsection>
    </div> -->
    <brandarea></brandarea>

    <!-- <banner></banner> -->
    <section>
      <selectioncontainer></selectioncontainer>
    </section>
    <section>
      <midbanner :lists="bannerlist.result3"></midbanner>
      <hotauctions></hotauctions>
    </section>
    <vechTypeSlider></vechTypeSlider>
    <midbanner :lists="bannerlist.result7"></midbanner>
    <!-- <showmodel></showmodel> -->
  </div>
</template>

<script>
import userService from "../services/user.service";
export default {
  name: "Home",
  data() {
    return {
      content: "",
      bannerlist: "",
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    if (this.loggedIn) {
      if (
        !this.$store.state.auth.user[0].sellerId == "" ||
        !this.$store.state.auth.user[0].sellerId == " "
      ) {
        this.$router.push("/vendor").catch(() => {
          "home";
        });
      } else {
        this.$router.push("/").catch(() => {
          "home";
        });
      }
    }
  },
  created() {
    // if (this.loggedIn) {
    //   this.$router.push('/MyAccount').catch(()=>{"login"});
    // }


    this.getBanners();


  },

  methods: {

    getBanners() {
      userService
        .getBanners()
        .then((response) => {
          this.bannerlist = response.data.Data;
          // console.log( response);
        })
        .catch((e) => {
          console.log(e);
          // this.$router.push("/Auction").catch(() => {
          //   "details/auctionbyname";
          // });
        });
    },
  },
};
</script>
